import * as types from "../types/modalidades";

const INITIAL_STATE = {
  loading: false,
  modalidades: [],
  modalidade: {},

  page: 1,
  per_page: 10,
  total: 0,

  modalidades_selecionada: {},
  modalidade_selecionada_page: 1,
  modalidade_selecionada_per_page: 10,
  modalidade_selecionada_total: 0,

  categorias_secundarias: [],
  categoria_secundaria_page: 1,
  categoria_secundaria_per_page: 5,
  categoria_secundaria_total: 0,


  horariosModalidades: []
};


export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.MODALIDADES_LOADING:
      return { ...state, loading: action.payload };

    case types.MODALIDADES_DATA:
      return { ...state, modalidades: action.payload };



    case types.MODALIDADE_DATA:
      return { ...state, modalidade: action.payload };

    case types.HORARIOS_MODALIDADE_DATA:
      return { ...state, horariosModalidades: action.payload };


    case types.MODALIDADES_DATA_PAGE:
      return { ...state, page: action.payload };

    case types.MODALIDADES_DATA_PER_PAGE:
      return { ...state, per_page: action.payload };

    case types.MODALIDADES_DATA_TOTAL:
      return { ...state, total: action.payload };

      //MODALIDADE SELECIONADA

      case types.MODALIDADE_SELECIONADA_USUARIOS:
        return { ...state, modalidades_selecionada: action.payload };


    case types.MODALIDADE_SELECIONADA_DATA_PAGE:
      return { ...state, modalidade_selecionada_page: action.payload };

    case types.MODALIDADE_SELECIONADA_DATA_PER_PAGE:
      return { ...state, modalidade_selecionada_per_page: action.payload };

    case types.MODALIDADE_SELECIONADA_DATA_TOTAL:
      return { ...state, modalidade_selecionada_total: action.payload };

      // CATEGORIAS SECUNDARIAS

      
      case types.CATEGORIAS_SECUNDARIAS_DATA:
        return { ...state, categorias_secundarias: action.payload };

      case types.CATEGORIA_SECUNDARIA_PAGE:
        return { ...state, categoria_secundaria_page: action.payload };
  
      case types.CATEGORIA_SECUNDARIA_PER_PAGE:
        return { ...state, categoria_secundaria_per_page: action.payload };
  
      case types.CATEGORIA_SECUNDARIA_TOTAL:
        return { ...state, categoria_secundaria_total: action.payload };

    default:
      return state;
  }
};
