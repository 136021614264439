import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { CssBaseline } from '@mui/material';
import "./index.css";


ReactDOM.render((
  <>
  <CssBaseline />
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </>
), document.getElementById('root'));