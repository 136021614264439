import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DataTable } from "../../../components";
import { Paper } from "@mui/material";
import MaskCPFCNPJ from "../../../helpers/maskCpfCnpj";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import formatName from "../../../helpers/formatName";
import { formatCelular } from "../../../helpers";
import ButtonComponent from "../../../components/ButtonComponent";
import ModalAddEmpresa from "./cadastro/add";
import * as types from "../../../store/types/parceiros_integracao";
import Loading from "../../../components/Loading";
import { useParams } from "react-router-dom";
import { loadIntegradoresUsuarios } from "../../../store/actions/parceiros_integracao";
import dayjs from "dayjs";

function UsuariosParceirosIntegracaoApp() {
  const { parceiro_id } = useParams();
  const dispatch = useDispatch();
  const {
    usuarios_parceiros,
    usuario_page,
    usuario_per_page,
    usuario_total,
    parceiro_selecionada_usuario,
  } = useSelector((state) => state.parceiros_integracao);
  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");
  const [openModalInformations, setOpenModalInformations] = useState(false);
  const [modalOpenAdd, setModalOpenAdd] = useState(false);
  const [dataModalInformations, setDataModalInformations] = useState({});
  const [loading, setLoading] = useState(false);

  async function GetDataLoad() {
    setLoading(true);
    const data = {
      page: usuario_page,
      per_page: usuario_per_page,
      filtro: filtroAtualizado,
      status: "A",
    };
    await loadIntegradoresUsuarios(data, parceiro_id);
    setLoading(false);

    setFiltroAtualizado(""); // Limpa o estado após a requisição
  }

  const navigatePages = (validations) => {
    dispatch({ type: types.USUARIOS_PARCEIROS_DATA_PAGE, payload: validations });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, usuario_page]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, usuario_page]);



  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    margin: 15,
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };

  const columns = [
    { title: "Id", render: ({ id }) => id },
    { title: "Criado em", render: ({ criado_em }) => criado_em ? dayjs(criado_em).format('DD/MM/YYYY') : criado_em },
    { title: "Nome", render: ({ nome }) => nome },
    { title: "E-mail", render: ({ email }) => email },
   
  ];

  const handleRowClick = (event, rowData) => {
    console.log("-->", rowData);
    setDataModalInformations(rowData);
    setOpenModalInformations(true);
  };

  return (
    <div>
      <Loading loading={loading} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h4>
          Parceiros integração: {formatName(parceiro_selecionada_usuario.nome)}
        </h4>
        <ButtonComponent onClick={() => setModalOpenAdd(true)}>
          Adicionar usuário
        </ButtonComponent>
      </div>
      <Paper elevation={1} style={{ padding: 5 }}>
        <DataTable
          columns={columns}
          data={usuarios_parceiros}
          enableHover={true}
          options={{
            defaultMessage: "Não há registros para serem exibidos.",
            rowStyle: { backgroundColor: "#ffffff", cursor: "pointer" },
            cellStyle: { border: "1px solid #ddd" },
          }}
          onRowClick={handleRowClick}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            fontFamily: "Poppins",
            fontSize: 14,
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{ marginRight: 30, display: "flex", flexDirection: "row" }}
          >
            {+usuario_page !== 1 && (
              <button
                style={styledBtnArrows}
                onClick={() => navigatePages(+usuario_page - 1)}
              >
                <KeyboardArrowLeftOutlined /> Anterior{" "}
              </button>
            )}

            {usuarios_parceiros?.length !== 0 && (
              <button
                style={styledBtnArrows}
                onClick={() => navigatePages(+usuario_page + 1)}
              >
                Próximo <KeyboardArrowRightOutlined />
              </button>
            )}
          </div>
          <p>
            Pagina <b>{usuario_page}</b> | Total de registros{" "}
            <b>{usuario_total}</b>
          </p>
        </div>
      </Paper>

      <ModalAddEmpresa
        open={modalOpenAdd}
        parceiro_id={parceiro_id}
        close={() => setModalOpenAdd(false)}
        updateList={() => GetDataLoad()}
      />
    </div>
  );
}

export default UsuariosParceirosIntegracaoApp;
