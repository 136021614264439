import styled from "@emotion/styled";

export const CameraVideo = styled.div`
  width: 100%;
  height: 300px;
  background-color: #4c4c4c;
  padding: 15px;
  color: #ffffff;
  border-radius: 15px;
  font-family: "Poppins";
`;

export const ButtonSelect = styled.button`
  background: transparent;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px;
  &:hover {
    background-color: #efefef;
    transition: 0.3s ease-in-out;
    color: #0a3649;
  }
`;



