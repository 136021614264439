import * as types from '../types/clicklifee';

const INITIAL_STATE = {
    especialidades: [],
    especialidade: {},
    questionario: {},
    anexos: [],
    paciente: {
      pacienteId: 0,
      usuarioId: 0,
      perfil: 0,
      nome: "",
      login: "",
      email: "",
      cliente: ""
    },
    questionario_formatado: {},
    loading: false,
    cadastro:{
      nome: false,
      cpf: false,
      data_nascimento: false,
      sexo: false,
      celular: false,
      email: false
    },
    pacienteToken: "",
    atendimentoId: "",
    atendimentoAberto: {
      atendimentoAberto: false,
      atendimentoId: null
    }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CHANGE_CLICKLIFEE_PACIENTE:
      return { ...state, paciente: action.payload};
    case types.CHANGE_CLICKLIFEE_ESPECIALIDADES:
      return { ...state, especialidades: action.payload};
    case types.CHANGE_CLICKLIFEE_ESPECIALIDADE:
      return { ...state, especialidade: action.payload};
    case types.CHANGE_CLICKLIFEE_LOADING:
      return {...state, loading: action.payload};
    case types.CHANGE_CLICKLIFEE_QUESTIONARIO:
      return {...state, questionario: action.payload};
    case types.CHANGE_CLICKLIFEE_ANEXOS:
        return {...state, anexos: action.payload};

    case types.CHANGE_CLICKLIFEE_CADASTRO:
        return {...state, cadastro: action.payload};

    case types.CHANGE_CLICKLIFEE_QUESTIONARIO_FORMATADO:
        return {...state, questionario_formatado: action.payload};

    case types.CHANGE_CLICKLIFEE_PACIENTE_TOKEN:
        return {...state, pacienteToken: action.payload};

    case types.CHANGE_CLICKLIFEE_ATENDIMENTO_ID:
        return {...state, atendimentoId: action.payload};


    case types.CHANGE_CLICKLIFEE_ATENDIMENTO_ABERTO:
        return {...state, atendimentoAberto: action.payload};


    default:
      return state;
  }
};