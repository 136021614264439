


import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/usuarios";

export function loadUsuarios(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIOS_LOADING, payload: true });
    http.get(`/api/v1/usuarios/adm/listar?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.USUARIOS_DATA, payload: response.data.usuarios },
          { type: types.USUARIOS_DATA_PAGE, payload: response.data.page },
          { type: types.USUARIOS_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.USUARIOS_DATA_TOTAL, payload: response.data.total },
          { type: types.USUARIOS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.USUARIOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}




export function ActionCadatrarUsuario(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIOS_LOADING, payload: true });
    http.post(`/api/v1/usuarios/adm/cadastro`, data).then(
      (response) => {
        store.dispatch([{ type: types.USUARIOS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.USUARIOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionAtualizarEmpresa(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIOS_LOADING, payload: true });
    http.put(`/api/v1/usuarios/adm/atualizar`, data).then(
      (response) => {
        store.dispatch([{ type: types.USUARIOS_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.USUARIOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}



export function ActionExcluirEmpresaEClientes(id) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIOS_LOADING, payload: true });
    http.delete(`/api/v1/usuarios/adm/excluir/${id}`).then(
      (response) => {
        store.dispatch([         
          { type: types.USUARIOS_LOADING, payload: false },
        ]);

        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.USUARIOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionCadatrarClienteExcel(id, data) {
  const formData = new FormData();
  formData.append('excelFile', data); 

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIOS_LOADING, payload: true });
    http.post(`/api/v1/cliente/excel/${id}`,  formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }}).then(
      (response) => {
        store.dispatch([
          { type: types.USUARIOS_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.USUARIOS_LOADING, payload: false });
        reject(e);
      }
    );
  });
}