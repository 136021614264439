import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import ButtonComponent from "../../components/ButtonComponent";
import {
  EntarTriagemAction,
  EntrarAtendimentoAction,
  ListarAtendimentoNaoFinalizado,
  ListarAtendimentosAbertosAction,
} from "../../store/actions/triagem";
import { loadUsuario } from "../../store/actions/usuario";
import { urlApi } from "../../services/url";

function AtendimentosAbertosScreen() {
  const { atendimentosNaoFinalizados } = useSelector((state) => state.triagem);
  const [socketEvent, setSocketEvent] = useState(false);
  const navigate = useNavigate();
  const { usuario, permissoes } = useSelector((state) => state.usuario);

  console.log(atendimentosNaoFinalizados);

  useEffect(() => {
    loadUsuario();
    const socket = io(urlApi);

    socket.on("salaCriada", (data) => {
      setSocketEvent(true);
      setTimeout(() => {
        setSocketEvent(false);
      }, 3000);

      // Faça o que precisar com a informação recebida do servidor
    });

    socket.on("transferenciaChamada", (data) => {
      setSocketEvent(true);
      setTimeout(() => {
        setSocketEvent(false);
      }, 3000);
    });

    return () => {
      socket.disconnect(); // Desconecte o socket quando o componente for desmontado
    };
  }, []);

  useEffect(() => {
    ListarAtendimentoNaoFinalizado();
  }, [socketEvent]);

  function calcularTempoEmEspera(data_inicio) {
    const inicio = new Date(data_inicio);
    const agora = new Date();
    const diferenca = agora - inicio;

    const minutos = Math.floor(diferenca / (1000 * 60));
    const horas = Math.floor(minutos / 60);
    const minutosRestantes = minutos % 60;

    if (horas > 0) {
      return `${horas} hora(s) e ${minutosRestantes} minuto(s)`;
    } else {
      return `${minutos} minuto(s)`;
    }
  }

  const styleBtn = {
    border: "none",
    borderRadius: 7,
    cursor: "pointer",
    background: "transparent",
    textDecoration: "underline",
  };

  async function EntarChamadaTriagem(data) {
    try {
      if (permissoes.tipo === "TRIAGEM") {
        await EntarTriagemAction({ sala: data.sala }).then((res) => {
          navigate(`/app/home/${res.sala}`, { replace: true });
        });
      } else if (permissoes.tipo === "ATENDIMENTO") {
        await EntrarAtendimentoAction({ sala: data.sala }).then((res) => {
          navigate(`/app/home/atendimento/${res.sala}`, { replace: true });
        });
      } else {
        throw console.error("erro");
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <h1>Atendimentos abertos não finalizados</h1>
      <Grid container spacing={1}>
        {atendimentosNaoFinalizados?.map((at, index) => {
          return (
            <Grid sm={4}>
              <div key={index.id} style={{ padding: 15 }}>
                <div
                  style={{
                    padding: 15,
                    border: "1px solid #191919",
                    borderRadius: 7,
                  }}
                >
                  <b>Cliente:</b> {at?.nome} <br />
                  <b>Modalidade:</b> {at?.modalidade} <br />
                  <b>Tempo de espera:</b>{" "}
                  {calcularTempoEmEspera(at?.data_inicio)}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 15,
                    }}
                  >
                    <ButtonComponent onClick={() => EntarChamadaTriagem(at)}>
                      Entrar na chamada
                    </ButtonComponent>
                    <button style={{ ...styleBtn }}>Recusar chamada</button>
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default AtendimentosAbertosScreen;
