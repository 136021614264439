import http from "../../services/api";
import store from "../index";
import * as types from "../types/triagem";

export function ListarAtendimentosAbertosAction() {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
    http.get(`/api/v1/atendimento/atendimentos-abertos`).then(
      (response) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_ABERTOS,payload: response.data});
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        reject(e);
      }
    );
  });
}
export function ListarAtendimentoNaoFinalizado() {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
    http.put(`/api/v1/atendimento/atendimentos-nao-finalizados`).then(
      (response) => {
    
        store.dispatch({ type: types.SET_ATENDIMENTOS_NAO_FINALIZADOS,payload: response.data});
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        resolve(response.data);
      },
      (e) => {
        console.log('errooooo', e)
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        reject(e);
      }
    );
  });
}


export function EntarTriagemAction(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
    http.put(`/api/v1/atendimento/entrar-triagem`, data).then(
      (response) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        reject(e);
      }
    );
  });
}


export function EntrarAtendimentoAction(data) {

  console.log(data)
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
    http.put(`/api/v1/atendimento/entrar-atendimento`, data).then(
      (response) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        reject(e);
      }
    );
  });
}


export function TransferirChamadaAction(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
    http.put(`/api/v1/atendimento/transferir-chamada`, data).then(
      (response) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        reject(e);
      }
    );
  });
}


export function ListarAtendimentoPorSalaAction(sala) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
    http.get(`/api/v1/atendimento/${sala}`).then(
      (response) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        reject(e);
      }
    );
  });
}


export function ListarAtendentesOnlineAction(modalidade_id) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
    http.get(`/api/v1/atendimento/atendentes-online/${modalidade_id}`).then(
      (response) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        store.dispatch({ type: types.SET_ATENDENTES_ONLINE, payload: response.data });
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        reject(e);
      }
    );
  });
}



export function FinalizarChamadaAtendimento(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
    http.put(`/api/v1/atendimento/finalizar-chamada`, data).then(
      (response) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.SET_ATENDIMENTOS_LOADING, payload: true });
        reject(e);
      }
    );
  });
}



export function StatusPausaTriagem() {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.LOADING_HORARIOS_DE_ATENDIMENTO, payload: true });
    http.patch(`/api/v1/atendimento/horarios/status`).then(
      (response) => {
        store.dispatch({ type: types.SET_HORARIOS_ATENDIMENTOS, payload: response.data });
        store.dispatch({ type: types.LOADING_HORARIOS_DE_ATENDIMENTO, payload: false });
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.LOADING_HORARIOS_DE_ATENDIMENTO, payload: false });
        reject(e);
      }
    );
  });
}