import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { responseInterceptor, requestInterceptor} from "./services/interceptors";

import store from "./store/index";
import routes from "./routes";

const browserHistory = createBrowserHistory();

responseInterceptor(store);
requestInterceptor(store, browserHistory);

export function App() {
  const routing = useRoutes(routes);

  return <Provider store={store}>{routing}</Provider>;
}
