import {
  useMediaQuery,
  useTheme,
  Drawer as MuiDrawer,
  styled,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";

import { useDrawerContext } from "../contexts/drawer-context";
import { MenuItemsList } from "./MenuItemsList";
import { useEffect, useState } from "react";

const Container = styled("div")({
  background: "#f1f1f1",
  padding: 8,
  whiteSpace: "nowrap",
});

const CustomPaper = styled(Paper)({
  background: "#212123",
  padding: 15,
});

export const Drawer = () => {
  const { isOpened, toggleIsOpened, menu } = useDrawerContext();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const permisssao = localStorage.getItem("permissao");

  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
    setLoading(false);
  }, [permisssao]);

  return (
    <>
      {loading && (
        <Container>
          <CustomPaper elevation={8}>
            <MenuItemsList items={menu} isOpened={isOpened} />
          </CustomPaper>
        </Container>
      )}
    </>
  );
};
