import { Layout } from "./components";
import {
  Login
} from "./pages";

import { Navigate } from "react-router-dom";
import ChamadaAtendimentoRoute from "./pages/chamadaAtendimento";
import ChamadaTriagem from "./pages/chamadaTriagem";
import HomeScreen from "./pages/home";
import AtendimentosAbertosScreen from "./pages/atendimentosAbertos";
import AtendimentosFinalizados from "./pages/atendimentosFinalizados";
import EmpresasApp from "./pages/empresas";
import ModalidadesApp from "./pages/modalidades";
import IntegradoresApp from "./pages/parceirosIntegracao";
import UsuariosEmpresasApp from "./pages/empresas/usuarios";
import QuestionarioModalidadesApp from "./pages/modalidades/questionario";
import UsuariosParceirosIntegracaoApp from "./pages/parceirosIntegracao/usuarios";
import UsuariosApp from "./pages/usuarios";

const routes = [
  { path: "/", element: <Navigate to="/login" replace={true} /> },
  { path: "/login", element: <Login /> },

  {
    path: "/app",
    element: <Layout />,
    children: [

      { path: "/app/home", element: <HomeScreen /> },
      { path: "/app/empresas", element: <EmpresasApp /> },
      { path: "/app/empresas/:empresa_id", element: <UsuariosEmpresasApp /> },
      { path: "/app/parceiros", element: <IntegradoresApp /> },
      { path: "/app/parceiros/:parceiro_id", element: <UsuariosParceirosIntegracaoApp /> },
      { path: "/app/modalidades", element: <ModalidadesApp /> },
      { path: "/app/usuarios", element: <UsuariosApp /> },
      { path: "/app/modalidades/:id", element: <QuestionarioModalidadesApp /> },
      { path: "/app/atendimento-aberto", element: <AtendimentosAbertosScreen /> },
      { path: "/app/home/:urlRoom", element: <ChamadaTriagem /> },
      { path: "/app/home/atendimento/:urlRoom", element: <ChamadaAtendimentoRoute /> },
      { path: "/app/atendimento/finalizados", element: <AtendimentosFinalizados /> },
    ],
  },
];

export default routes;
