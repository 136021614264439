import { useEffect, useState } from "react";
import { DataTable, Paginate } from "../../components";
import { AtendimentosFinalizadosUsuario } from "../../store/actions/usuario";
import { useDispatch, useSelector } from "react-redux";
import { formatCPF, formatCelular } from "../../helpers";
import formatName from "../../helpers/formatName";
import dayjs from "dayjs";
import { Divider, Paper, Tooltip } from "@mui/material";
import * as types from "../../store/types/usuario";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import Loading from "../../components/Loading";
import ModalUsuario from "./modalUsuario";

function AtendimentosFinalizados() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openModalInformations, setOpenModalInformations] = useState(false);
  const [dataModalInformations, setDataModalInformations] = useState({});
  const {
    atendimentosFinalizados,
    atendimentoPage,
    atendimentoPerPage,
    atendimentoTotal,
  } = useSelector((state) => state.usuario);

  async function UpdateAtendimentosFinalizados() {
    setLoading(true);
    try {
      await AtendimentosFinalizadosUsuario({
        page: atendimentoPage,
        per_page: atendimentoPerPage,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    UpdateAtendimentosFinalizados();
  }, [atendimentoPage, atendimentoPerPage]);

  const columns = [
    { title: "Nome", render: ({ nome_cliente }) => formatName(nome_cliente) },
    { title: "Documento", render: ({ cpf_cliente }) => formatCPF(cpf_cliente) },
    { title: "Telefone", render: ({ telefone }) => formatCelular(telefone) },
    {
      title: "Categoria",
      render: (data) => {
        return (
          <div>
            <Tooltip title={data.categoria_secundaria}>
              <div>{data.categoria_principal}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Início atendimento",
      render: ({ data_inicio }) =>
        dayjs(data_inicio).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Término atendimento",
      render: ({ data_termino }) =>
        dayjs(data_termino).format("DD/MM/YYYY HH:mm"),
    },
  ];

  const navigatePages = (validations) => {
    console.log(validations);
    dispatch({ type: types.ATENDIMENTO_FINALIZADO_PAGE, payload: validations });
  };

  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    margin: 15,
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };

  const handleRowClick = (event, rowData) => {
    console.log(rowData)
    setDataModalInformations(rowData);
    setOpenModalInformations(true);
  };

  return (
    <div>
      <strong>ATENDIMENTOS FINALIZADOS</strong>
      <Loading loading={loading} />
      <Divider />
      <Paper elevation={1} style={{ padding: 5 }}>
        <DataTable
          columns={columns}
          data={atendimentosFinalizados.data}
          enableHover={true}
          options={{
            defaultMessage: "Não há registros para serem exibidos.",
            rowStyle: { backgroundColor: "#ffffff", cursor: "pointer" },
            cellStyle: { border: "1px solid #ddd" },
          }}
          onRowClick={handleRowClick}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            fontFamily: "Poppins",
            fontSize: 14,
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{ marginRight: 30, display: "flex", flexDirection: "row" }}
          >
            {+atendimentoPage !== 1 && (
              <button
                style={styledBtnArrows}
                onClick={() => navigatePages(+atendimentoPage - 1)}
              >
                <KeyboardArrowLeftOutlined /> Anterior{" "}
              </button>
            )}

            {atendimentosFinalizados?.data?.length !== 0 && (
              <button
                style={styledBtnArrows}
                onClick={() => navigatePages(+atendimentoPage + 1)}
              >
                Próximo <KeyboardArrowRightOutlined />
              </button>
            )}
          </div>
          <p>
            Pagina <b>{atendimentoPage}</b> | Total de registros{" "}
            <b>{atendimentoTotal}</b>
          </p>
        </div>
      </Paper>
      <ModalUsuario open={openModalInformations} data={dataModalInformations} close={() => setOpenModalInformations(false)}/>
    </div>
  );
}

export default AtendimentosFinalizados;
