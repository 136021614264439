import { Avatar, CircularProgress, Divider, Grid, Paper } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { InputText, Modal } from "../../components";
import ButtonComponent from "../../components/ButtonComponent";
import formatName from "../../helpers/formatName";
import {
  EntarTriagemAction,
  FinalizarChamadaAtendimento,
  ListarAtendentesOnlineAction,
  ListarAtendimentoPorSalaAction,
  TransferirChamadaAction,
} from "../../store/actions/triagem";
import { ButtonSelect, CameraVideo } from "./style";
import { MdGroupAdd } from "react-icons/md";
import PartesEnvolvidas from "./partesEnvolvidas";
import CategoriaAtendimentoSelecionada from "./categoriaAtendimentoSelecionada";
import ModalEncaminhamentoChamada from "./modalAtendenteEncaminhamento";
import Room from "../../components/VideoCall/Room";
import io from "socket.io-client";
import { useRoom } from "../../hooks/useTwilioVideo";
import { urlApi } from "../../services/url";
import { Link } from "react-router-dom";

function ChamadaTriagem() {
  const {
    room,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone,
  } = useRoom();

  const navigate = useNavigate();
  const { urlRoom } = useParams();
  const { atendentesOnline } = useSelector((state) => state.triagem);
  const [dataRoom, setDataRoom] = useState({});
  const [descricao, setDescricao] = useState("");
  const [criarDescricao, setCriarDescricao] = useState(false);
  const [atendenteSelected, setAtendenteSelected] = useState(false);
  const [atendenteSelectedData, setAtendenteSelectedData] = useState({});
  const [partesEnvolvidas, setPartesEnvolvidas] = useState([]);
  const [modalAtendente, setModalAtendente] = useState(false);
  const [categoriaId, setCategoriaId] = useState();
  const [status, setStatus] = useState("disconnected");
  const [loadConfirmSelect, setLoadConfirmSelect] = useState(false);
  const [tokenConnect, setTokenConnect] = useState("");
  const [loading, setLoading] = useState(false);
  const [categoriaSecundariaSelecionada, setCategoriaSecundariaSelecionada] =
    useState("");

  const [usuarios, setUsuarios] = useState([]);
  const [socketEvent, setSocketEvent] = useState(false);

  useEffect(() => {
    if (dataRoom) {
      setUsuarios(dataRoom.partes_envolvidas ? dataRoom.partes_envolvidas : []);
      setDescricao(dataRoom.triagem_descricao);
    }
  }, [dataRoom]);

  async function FinalizarChamada() {
    try {
      await FinalizarChamadaAtendimento({ sala: dataRoom.sala });
      localStorage.setItem("atendimento", "DISPONIVEL");
      disconnectRoom();
      navigate("/app/home");
      window.location.reload()
    } catch (e) {
      console.log(e);
    }
  }




  useEffect(() => {
    const socket = io(urlApi);

    socket.on("atendimento-finalizado", async (data) => {
      await ListarAtendimentoPorSalaAction(urlRoom).then((res) => {      
        if (data.sala === res.sala) {
          disconnectRoom();
          navigate("/app/home");
         window.location.reload()
        }
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);



  const [novoUsuario, setNovoUsuario] = useState({
    nome: "",
    documento: "",
    tipo: "",
  });

  async function ListagemAtendimentosSala() {
    await ListarAtendimentoPorSalaAction(urlRoom).then((res) => {
      setDataRoom(res);
    });
  }

  useEffect(() => {
    ListagemAtendimentosSala();
  }, []);

  async function UpdateInformationsCategorys(data) {
    try {
      await EntarTriagemAction({
        sala: urlRoom,
        triagem_descricao: descricao,
        categoria_secundaria: categoriaSecundariaSelecionada,
        partes_envolvidas: JSON.stringify(usuarios),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function EntarChamadaTriagem(data) {
    try {
      await UpdateInformationsCategorys();
      await ListagemAtendimentosSala();
      setCriarDescricao(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function ListarAtendentesOnline() {
    setLoading(true);
    try {
      await UpdateInformationsCategorys();
      await ListarAtendentesOnlineAction(dataRoom.modalidade_id);
      setModalAtendente(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  function SelecionarAtendente(data) {
    setAtendenteSelectedData(data);
    setAtendenteSelected(true);
  }

  function EscolherOutro(data) {
    setAtendenteSelectedData({});
    setAtendenteSelected(false);
  }

  async function TransferirChamada() {
    setLoadConfirmSelect(true);
    try {
      await TransferirChamadaAction({
        sala: dataRoom.sala,
        atendente_id: atendenteSelectedData.usuario_id,
      });
      localStorage.setItem("atendimento", "DISPONIVEL");
      disconnectRoom();
      setLoadConfirmSelect(true);
      navigate("/app/home");
    } catch (e) {
      setLoadConfirmSelect(false);
    }
  }

  const salvarUsuarios = () => {
    // Faça algo com a lista final de usuários (pode ser enviado para o servidor, por exemplo)
    console.log("Usuários a serem salvos:", usuarios);
  };

  async function CancelarAtendimento() {
    console.log("cancelar atendimento");
  }

  const colorBtn = {
    background: "#2e871b",
    border: "none",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 500,
    flexDirection: "row",
    display: "flex",
    padding: "15px 25px",
    cursor: "pointer",
    borderRadius: 9,
    width: "100%",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div style={{ height: "100%", marginBottom: 200 }}>
      <Paper elevation={4} style={{ marginBottom: 30 }}>
        <Grid container spacing={1} style={{ padding: 15 }}>
          <Grid item xs={3} sm={3}>
            <div>
              Cliente: {formatName(dataRoom.nome)} <br />
              Modalidade: {formatName(dataRoom.modalidade)} <br />
              Parceiro: {formatName(dataRoom.parceiro_integracao_nome)}
            </div>
          </Grid>
          <Grid item xs={5} sm={5}>
            <CategoriaAtendimentoSelecionada
              categoriaId={categoriaId}
              modalidadeId={dataRoom.modalidade_id}
              categoriaSecundariaSelecionada={categoriaSecundariaSelecionada}
              setCategoriaSecundariaSelecionada={
                setCategoriaSecundariaSelecionada
              }
              data={dataRoom}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <button
              style={{ ...colorBtn, backgroundColor: "red" }}
              onClick={() => FinalizarChamada()}
            >
              Finalizar Chamada
            </button>
          </Grid>
          <Grid item xs={2} sm={2}>
            {!loading ? (
              <button
                style={{ ...colorBtn }}
                onClick={() => ListarAtendentesOnline()}
              >
                Encaminhar at.
              </button>
            ) : (
              <div style={{ ...colorBtn, backgroundColor: "#135f66" }}>
                {" "}
                <CircularProgress
                  style={{ color: "#fff", width: 15, height: 15 }}
                />
                &nbsp;Aguarde
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={1}>
        <Grid item xs={4} sm={4}>
          <Paper elevation={3}>
            <Room
              token={dataRoom.token}
              cancelar={CancelarAtendimento}
              room={room}
              connectRoom={connectRoom}
              disconnectRoom={disconnectRoom}
              localParticipant={localParticipant}
              remoteParticipants={remoteParticipants}
              isCameraOn={isCameraOn}
              toggleCamera={toggleCamera}
              isMicrophoneOn={isMicrophoneOn}
              toggleMicrophone={toggleMicrophone}
            />
          </Paper>
        </Grid>

        <Grid item xs={5} sm={5}>
          <Divider />
          <Paper elevation={3}>
            <div style={{ padding: 5 }}>
              <h4>Quem receberá atendimento</h4>
              <Divider />
              <PartesEnvolvidas
                novoUsuario={novoUsuario}
                setNovoUsuario={setNovoUsuario}
                usuarios={usuarios}
                setUsuarios={setUsuarios}
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Divider />
          {criarDescricao ? (
            <>
              <h4>Digite os detalhes da chamada </h4>
              <textarea
                value={descricao}
                style={{
                  height: "50%",
                  borderRadius: 10,
                  border: "1px solid #8e8e8e",
                  width: "100%",
                  resize: "none",
                  overflowY: "auto",
                  whiteSpace: "pre-wrap",
                }}
                onChange={(e) => setDescricao(e.target.value)}
              />
              <div>
                <ButtonComponent
                  onClick={() => EntarChamadaTriagem()}
                  fontWeight="500"
                  fullWidth={true}
                >
                  Salvar comentário
                </ButtonComponent>
              </div>
            </>
          ) : (
            <div>
              {dataRoom?.triagem_descricao?.length > 0 ? (
                <div>
                  <h4>Descrição adicionada</h4>

                  <h5>
                    Atualizado em:{" "}
                    {dayjs(dataRoom.updated_at).format("DD/MM/YYYY HH:mm")}{" "}
                    <br />
                    Triagem: {formatName(dataRoom.nomeAtendenteTriagem)}
                  </h5>
                  <div
                    style={{
                      height: 150,
                      overflowY: "scroll",
                      fontSize: 14,
                      marginBottom: 20,
                    }}
                  >
                    {dataRoom?.triagem_descricao}
                  </div>

                  <ButtonComponent
                    onClick={() => setCriarDescricao(true)}
                    fontWeight="500"
                    fullWidth={true}
                  >
                    Editar descrição
                  </ButtonComponent>
                </div>
              ) : (
                <div>
                  <h4>Clique abaixo para adicionar uma descrição</h4>
                  <ButtonComponent
                    onClick={() => setCriarDescricao(true)}
                    fontWeight="500"
                    fullWidth={true}
                  >
                    Adicionar descrição
                  </ButtonComponent>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>

      <ModalEncaminhamentoChamada
        setModalAtendente={setModalAtendente}
        modalAtendente={modalAtendente}
        atendentesOnline={atendentesOnline}
        atendenteSelected={atendenteSelected}
        SelecionarAtendente={SelecionarAtendente}
        atendenteSelectedData={atendenteSelectedData}
        TransferirChamada={TransferirChamada}
        EscolherOutro={EscolherOutro}
        loading={loadConfirmSelect}
        colorBtn={colorBtn}
      />
    </div>
  );
}

export default ChamadaTriagem;
