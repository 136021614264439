import axios from "axios";
import { urlApi } from "./url";


const http = axios.create({ baseURL: urlApi, timeout: 200000 });

let isRefreshing = false;
let refreshSubscribers = [];

// Função para adicionar subscritores ao refresh token
function onRefreshed(token) {
  refreshSubscribers.map(callback => callback(token));
}

// Função para adicionar subscritores
function addRefreshSubscriber(callback) {
  refreshSubscribers.push(callback);
}

// Interceptor de requisição
http.interceptors.request.use(
  async (config) => {
    const refreshTokenFromDatabase = localStorage.getItem('token');

    if (
      config.url && (
      config.url === "/api/v1/usuarios/autenticar" ||
      config.url === "/api/v2/app/cliente/redefinir-senha/solicitar" ||
      config.url === "/api/v2/app/cliente/redefinir-senha/validar" ||
      config.url === "/api/v2/config?app" ||
      config.url === "/api/v2/app/cliente/redefinir-senha" ||
      config.url.startsWith("/api/v2/app/cliente/verificacao") ||
      (config.url === "/api/v2/app/cliente" && config.method && config.method === "post"))
    ) {
      return config;
    }

    if (refreshTokenFromDatabase) {
      config.headers.Authorization = `Bearer ${refreshTokenFromDatabase}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor de resposta
http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Redirecionar para a página de login
      window.location.href = '/login';
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default http;
