import { applyMiddleware, combineReducers, createStore } from "redux";
import multi from "redux-multi";
import thunk from "redux-thunk";

import { clicklife, empresas, modalidades, parceiros_integracao, triagem, usuario, usuarios } from "./reducers";

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const reducers = combineReducers({
  usuario,
  clicklife,
  triagem,
  parceiros_integracao,
  empresas,
  usuarios,
  modalidades
});

export default applyMiddleware(thunk, multi)(createStore)(
  reducers,
  process.env.NODE_ENV === "development" ? devTools : undefined
);
