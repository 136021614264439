import * as types from '../types/triagem';

const INITIAL_STATE = {
    loading: false,
    atendimentosAbertos: [],
    atendentesOnline: [],
    atendimentosNaoFinalizados: [],
    horariosAtendimentoTriagem: {},
    play_song: false

};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_ATENDIMENTOS_ABERTOS:
      return { ...state, atendimentosAbertos: action.payload};

    case types.SET_ATENDIMENTOS_LOADING:
      return {...state, loading: action.payload};

    case types.SET_ATENDENTES_ONLINE:
      return {...state, atendentesOnline: action.payload};

    case types.SET_ATENDIMENTOS_NAO_FINALIZADOS:
      return {...state, atendimentosNaoFinalizados: action.payload};

    case types.SET_HORARIOS_ATENDIMENTOS:
      return {...state, horariosAtendimentoTriagem: action.payload};

    case types.SET_PLAY_SONG:
      return {...state, play_song: action.payload};


    default:
      return state;
  }
};