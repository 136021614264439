import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DataTable } from "../../../../components";
import { Paper } from "@mui/material";
import {
  FormatListBulletedOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import ButtonComponent from "../../../../components/ButtonComponent";
import * as types from "../../../../store/types/modalidades";
import Loading from "../../../../components/Loading";
import {
  loadCategoriaSecundaria,
  loadModalidades,
  loadModalidadesPorId,
} from "../../../../store/actions/modalidades";
import ModalAdd from "./cadastro/add";
import { useParams } from "react-router-dom";
import ModalComponent from "../../../../components/MDModal";

function CategoriasSecundarias({ open, data, close }) {
  const dispatch = useDispatch();

  console.log(data);

  const {
    categorias_secundarias,
    categoria_secundaria_page,
    categoria_secundaria_per_page,
    categoria_secundaria_total,
  } = useSelector((state) => state.modalidades);

  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");
  const [openModalInformations, setOpenModalInformations] = useState(false);
  const [modalOpenAdd, setModalOpenAdd] = useState(false);
  const [dataModalInformations, setDataModalInformations] = useState({});
  const [loading, setLoading] = useState(false);

  async function GetDataLoad() {
    setLoading(true);
    const _data = {
      page: categoria_secundaria_page,
      per_page: categoria_secundaria_per_page,
      filtro: filtroAtualizado,
      modalidade_id: data.id,
      status: "A",
    };
    await loadCategoriaSecundaria(_data);
    setLoading(false);

    setFiltroAtualizado(""); // Limpa o estado após a requisição
  }

  const navigatePages = (validations) => {
    dispatch({ type: types.CATEGORIA_SECUNDARIA_PAGE, payload: validations });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, categoria_secundaria_page, open]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, categoria_secundaria_page, open]);


  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    margin: 15,
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };

  const columns = [
    { title: "Id", render: ({ id }) => id },
    {
      title: data.categoria_principal,
      render: ({ categoria_secundaria }) => categoria_secundaria,
    },
  ];

  const handleRowClick = (event, rowData) => {
    console.log(rowData);
    setDataModalInformations(rowData);
    setOpenModalInformations(true);
  };

  return (
    <ModalComponent open={open} width="50%">
      <Loading loading={loading} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h4>Categorias: {data.categoria_principal}</h4>
        <ButtonComponent onClick={() => setModalOpenAdd(true)}>
          Adicionar categoria
        </ButtonComponent>
      </div>
      <Paper elevation={1} style={{ padding: 5 }}>
        <DataTable
          columns={columns}
          data={categorias_secundarias}
          enableHover={true}
          options={{
            defaultMessage: "Não há registros para serem exibidos.",
            rowStyle: { backgroundColor: "#ffffff", cursor: "pointer" },
            cellStyle: { border: "1px solid #ddd" },
          }}
          onRowClick={handleRowClick}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            fontFamily: "Poppins",
            fontSize: 14,
            justifyContent: "flex-end",
            marginBottom: 50,
          }}
        >
          <div
            style={{ marginRight: 30, display: "flex", flexDirection: "row" }}
          >
            {+categoria_secundaria_page !== 1 && (
              <button
                style={styledBtnArrows}
                onClick={() => navigatePages(+categoria_secundaria_page - 1)}
              >
                <KeyboardArrowLeftOutlined /> Anterior{" "}
              </button>
            )}

            {categorias_secundarias?.length !== 0 && (
              <button
                style={styledBtnArrows}
                onClick={() => navigatePages(+categoria_secundaria_page + 1)}
              >
                Próximo <KeyboardArrowRightOutlined />
              </button>
            )}
          </div>
          <p>
            Pagina <b>{categoria_secundaria_page}</b> | Total de registros{" "}
            <b>{categoria_secundaria_total}</b>
          </p>
        </div>
      </Paper>

      <ModalAdd
        open={modalOpenAdd}
        close={() => setModalOpenAdd(false)}
        updateList={() => GetDataLoad()}
        dataCategoria={data}
      />

      <ButtonComponent
        onClick={close}
        style={{ backgroundColor: "#3a3a3a" }}
        fullWidth
      >
        Fechar
      </ButtonComponent>
    </ModalComponent>
  );
}

export default CategoriasSecundarias;
