import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import apple from "../../assets/apple.jpg";
import fyndmockup from "../../assets/fyndmockup.png";
import google from "../../assets/google.jpg";
import icone from "../../assets/icone.png";
import qrcode from "../../assets/qrcode.png";
import { autenticarUsuario } from "../../store/actions/usuario";

import AlertComponent from "../../components/AlertComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { Box, Formulario, Home } from "./style";

function Login() {
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);

  function ShowPassword() {
    setShowPass(showPass ? false : true);
  }

  const navigate = useNavigate();

  const [data, setData] = useState({ email: "", password: "" });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  async function sendLogin(event) {
    event.preventDefault();
    try {
      const retornoLogin = await autenticarUsuario(data.email, data.password);
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
      console.log(retornoLogin)
      if (retornoLogin.permissao.tipo === "ADMINISTRADOR" || retornoLogin.permissao.tipo === "INTEGRACAO" ) {      
        navigate("/app/atendimento/finalizados", { replace: true });
      } else {
        navigate("/app/home", { replace: true });
      }
    } catch (error) {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  }

  return (
    <Home>
      <Grid container spacing={1}>
        <Grid sm={6}>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <div style={{ textAlign: "center", padding: "1em" }}>
                <img src={icone} style={{ width: "100px" }} />
              </div>
              <h4 style={{ textAlign: "center", fontWeight: "500" }}>
                Área do Cliente
              </h4>

              <Formulario onSubmit={sendLogin}>
                <label>
                  <div>
                    <TextField
                      type="email"
                      value={data.email}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      onChange={(event) =>
                        setData({
                          ...data,
                          email: event.target.value,
                        })
                      }
                      id="email"
                      label="E-mail"
                    />
                  </div>
                  <div>
                    <TextField
                      type={showPass ? "text" : "password"}
                      value={data.password}
                      onChange={(event) =>
                        setData({
                          ...data,
                          password: event.target.value,
                        })
                      }
                      id="password"
                      label="Senha"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      margin="dense"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ cursor: "Pointer", opacity: "0.7" }}
                            onClick={ShowPassword}
                          >
                            <IconButton onClick={ShowPassword}>
                              {showPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </label>
                <br />
                <div>
                  <ButtonComponent
                    type="submit"
                    fontWeight="500"
                    fullWidth={true}
                    disabled={showErrorAlert}
                  >
                    Entrar
                  </ButtonComponent>
                </div>
                <br />
              </Formulario>
              {/* <div style={{ paddingBottom: "2em", textAlign: "center" }}>
                <Link to="/esqueci" style={{ fontSize: 12 }}>
                  ESQUECI A SENHA
                </Link>
              </div> */}
              <h4 style={{ margin: 0, textAlign: "center", fontSize: 14 }}>
                Baixe nosso App!
              </h4>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={6}>
                  <div style={{ textAlign: "right", marginRight: 15 }}>
                    <img src={qrcode} style={{ width: "35%" }} />
                  </div>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <div style={{ textAlign: "left" }}>
                    <a
                      href="https://play.google.com/store/apps/details?id=br.com.conexo.saude"
                      target="_blank"
                    >
                      <img src={google} style={{ width: "45%" }} />
                    </a>{" "}
                    <br />
                    <a
                      href="https://apps.apple.com/br/app/conexo-saúde/id1542610505?platform=iphone"
                      target="_blank"
                    >
                      <img src={apple} style={{ width: "45%" }} />
                    </a>
                  </div>
                </Grid>
              </Grid>

              <br />
            </Box>
          </div>
        </Grid>
        <Grid sm={6}>
          <div
            style={{
              marginTop: 15,
            }}
          >
            <img src={fyndmockup} />
          </div>
        </Grid>
      </Grid>
      {showErrorAlert && (
        <>
          {data.password.length === 0 && data.email.length === 0 ? (
            <AlertComponent
              type="error"
              message="Digite os dados para autenticação"
              timeout={3000}
            />
          ) : data.password.length === 0 ? (
            <AlertComponent
              type="error"
              message="Digite a sua senha!"
              timeout={3000}
            />
          ) : data.email.length === 0 ? (
            <AlertComponent
              type="error"
              message="Digite o seu email!"
              timeout={3000}
            />
          ) : (
            <AlertComponent
              type="error"
              message="Usuário ou senha incorretos!"
              timeout={3000}
            />
          )}
        </>
      )}
    </Home>
  );
}

export default Login;
