import { Box, ThemeProvider, Toolbar, styled } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { Drawer } from "./Drawer";
import { Header } from "./Header";
import { Main } from "./Main";

import theme from "../theme/index";
import { useDispatch, useSelector } from "react-redux";
import { DrawerContextProvider } from "../contexts/drawer-context";
import { ListarAtendimentosAbertosAction } from "../store/actions/triagem";
import { loadUsuario, ValidationLogin } from "../store/actions/usuario";
import { io } from "socket.io-client";
import { urlApi } from "../services/url";
import videochamadapng from "../assets/videochamada.png";
import chamadadesconectada from '../assets/desconectado.png'
import ring from "../assets/music/ring.mp3";
import * as types from "../store/types/triagem";
import http from "../services/api";

const InnerContainer = styled(Box)`
  display: flex;
  flex: 1;
  overflow: hidden;
  height: inherit;
`;

export function Layout({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { usuario } = useSelector((state) => state.usuario);
  const { atendimentosAbertos, play_song } = useSelector(
    (state) => state.triagem
  );
  const permisssao = localStorage.getItem("permissao");
  const id_usuario = localStorage.getItem("id_usuario");
  const [socketEvent, setSocketEvent] = useState(false);
  const [playSound, setPlaySound] = useState(false); // Estado para controlar a reprodução do som
  const [flashingTitle, setFlashingTitle] = useState(false);
  const [atendimentoCancelado, setAtendimentoCancelado] = useState(false);
  const [dataSocket, setDataSocket] = useState("");
  const [titleInterval, setTitleInterval] = useState(null); // Intervalo para o título
  const atendimento = localStorage.getItem("atendimento");
  const quantidadeAtendimentos = atendimentosAbertos.length;

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    loadUsuario();
  }, []);

  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    const socket = io(urlApi);

    socket.on("salaCriada", (data) => {
      setDataSocket(data);
      setSocketEvent(true);
      setPlaySound(true); // Inicia a reprodução do som
      startFlashingTitle(); // Inicia a pisca da aba

      if (data.tipo === permisssao) {
        dispatch({ type: types.SET_PLAY_SONG, payload: true });

        if (Notification.permission === "granted") {
          const notification = new Notification("Nova chamada", {
            body: `Uma nova chamada foi solicitada: ${data.participants}`,
            icon: videochamadapng,
          });

          notification.onclick = () => {
            window.focus();
            navigate(`/app/home`);
          };
        }
      }

      setTimeout(() => {
        setSocketEvent(false);
        setPlaySound(false); // Para a reprodução do som após 30 segundos
        if (quantidadeAtendimentos === 0) stopFlashingTitle(); // Para a pisca da aba se não houver atendimentos abertos
      }, 30000);
    });

    socket.on("atendimento-finalizado", (data) => {
      setDataSocket(data);
      setAtendimentoCancelado(true);
      setTimeout(() => {
        setAtendimentoCancelado(false);
        setPlaySound(false);
        if (quantidadeAtendimentos === 0) stopFlashingTitle(); // Para a pisca da aba se não houver atendimentos abertos
      }, 1000);
    });

    socket.on("transferenciaChamada", (data) => {
      setSocketEvent(true);
      setDataSocket(data);
      setPlaySound(true);
      startFlashingTitle(); // Inicia a pisca da aba
      setTimeout(() => {
        setSocketEvent(false);
        setPlaySound(false);
        if (quantidadeAtendimentos === 0) stopFlashingTitle(); // Para a pisca da aba se não houver atendimentos abertos
      }, 30000);
    });

    return () => {
      socket.disconnect();
    };
  }, [atendimentosAbertos]);

  useEffect(() => {
    ListarAtendimentosAbertosAction();
  }, [socketEvent, atendimentoCancelado, dataSocket]);

  useEffect(() => {
    document.title = `(${quantidadeAtendimentos}) Atendimentos Abertos`;
    if (quantidadeAtendimentos === 0) stopFlashingTitle(); // Para a pisca da aba se não houver atendimentos abertos
  }, [atendimentosAbertos]);

  const startFlashingTitle = () => {
    if (!titleInterval) {
      const originalTitle = document.title;
      let flashTitle = false;
      const interval = setInterval(() => {
        document.title = flashTitle
          ? `(${quantidadeAtendimentos}) Atendimentos Abertos`
          : "⚠️ Novo Atendimento!";
        flashTitle = !flashTitle;
      }, 1000);
      setTitleInterval(interval);
    }
  };

  const stopFlashingTitle = () => {
    if (titleInterval) {
      clearInterval(titleInterval);
      setTitleInterval(null);
      document.title = `(${quantidadeAtendimentos}) Atendimentos Abertos`;
    }
  };



  useEffect(() => {
    const intervalId = setInterval(() => {
      ValidationLogin().catch(() => {
        if (Notification.permission === "granted") {
          const notification = new Notification("Você foi desconectado", {
            body: `Faça login novamente na plataforma`,
            icon: chamadadesconectada,
          });

          notification.onclick = () => {
            window.focus();
            navigate(`/login`);
          };
        }
      });
    }, 300000); // 300000 milissegundos = 5 minutos

    return () => clearInterval(intervalId);
  }, [navigate]); 

  console.log(dataSocket.tipo , permisssao)

  function CardSound() {
    return (
      <>
        {dataSocket.tipo === permisssao && play_song ? (
          <>
          {atendimento === 'DISPONIVEL' ? 
          <>
            {playSound && (
              <audio autoPlay>
                <source src={ring} type="audio/mp3" />
              </audio>
            )}
            </>
            :
            <>
            
            </>
          }
          </>
        ) : dataSocket.tipo === permisssao && play_song && id_usuario === dataSocket.atendente_id  ? (
          <>
            {playSound && (
              <audio autoPlay>
                <source src={ring} type="audio/mp3" />
              </audio>
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <DrawerContextProvider>
      <ThemeProvider theme={theme}>
        {/* <OuterContainer> */}
        <Header />
        <Toolbar />
        <InnerContainer>
          <Drawer />
          <Main>
            <Outlet />
            <CardSound />
          </Main>
        </InnerContainer>
        {/* </OuterContainer> */}
      </ThemeProvider>
    </DrawerContextProvider>
  );
}
