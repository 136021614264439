export const MODALIDADES_LOADING = "MODALIDADES_LOADING";

export const MODALIDADES_DATA = "MODALIDADES_DATA";

export const MODALIDADE_DATA = "MODALIDADE_DATA";

export const MODALIDADES_DATA_PAGE = "MODALIDADES_DATA_PAGE";
export const MODALIDADE_SELECIONADA_USUARIOS = "MODALIDADE_SELECIONADA_USUARIOS";
export const MODALIDADES_DATA_PER_PAGE = "MODALIDADES_DATA_PER_PAGE";
export const MODALIDADES_DATA_TOTAL = "MODALIDADES_DATA_TOTAL";


export const MODALIDADE_SELECIONADA_DATA_PAGE = "MODALIDADE_SELECIONADA_DATA_PAGE"
export const MODALIDADE_SELECIONADA_DATA_PER_PAGE = "MODALIDADE_SELECIONADA_DATA_PER_PAGE"
export const MODALIDADE_SELECIONADA_DATA_TOTAL = "MODALIDADE_SELECIONADA_DATA_TOTAL"


      
export const CATEGORIAS_SECUNDARIAS_DATA = "CATEGORIAS_SECUNDARIAS_DATA"
export const CATEGORIA_SECUNDARIA_PAGE = "CATEGORIA_SECUNDARIA_PAGE"
export const CATEGORIA_SECUNDARIA_PER_PAGE = "CATEGORIA_SECUNDARIA_PER_PAGE"
export const CATEGORIA_SECUNDARIA_TOTAL = "CATEGORIA_SECUNDARIA_TOTAL"



export const HORARIOS_MODALIDADE_DATA = "HORARIOS_MODALIDADE_DATA"