export const PARCEIROS_INTEGRACAO_LOADING = "PARCEIROS_INTEGRACAO_LOADING";

export const PARCEIROS_INTEGRACAO_DATA = "PARCEIROS_INTEGRACAO_DATA";

export const PARCEIRO_INTEGRACAO_DATA = "PARCEIRO_INTEGRACAO_DATA";

export const PARCEIROS_INTEGRACAO_DATA_PAGE = "PARCEIROS_INTEGRACAO_DATA_PAGE";

export const PARCEIROS_INTEGRACAO_DATA_PER_PAGE = "PARCEIROS_INTEGRACAO_DATA_PER_PAGE";
export const PARCEIROS_INTEGRACAO_DATA_TOTAL = "PARCEIROS_INTEGRACAO_DATA_TOTAL";




export const USUARIOS_PARCEIROS_DATA = "USUARIOS_PARCEIROS_DATA"
export const PARCEIRO_SELECIONADA_USUARIOS = "PARCEIRO_SELECIONADA_USUARIOS"
export const USUARIOS_PARCEIROS_DATA_PAGE = "USUARIOS_PARCEIROS_DATA_PAGE"
export const USUARIOS_PARCEIROS_DATA_PER_PAGE = "USUARIOS_PARCEIROS_DATA_PER_PAGE"
export const USUARIOS_PARCEIROS_DATA_TOTAL = "USUARIOS_PARCEIROS_DATA_TOTAL"