import { Divider, Grid, Paper, TextField, Tooltip } from "@mui/material";
import { DataTable, InputText, Modal } from "../../../../components";
import ButtonComponent from "../../../../components/ButtonComponent";
import { useEffect, useState } from "react";
import ModalComponent from "../../../../components/MDModal";
import formatName from "../../../../helpers/formatName";
import Loading from "../../../../components/Loading";
import { ActionCadatrarEmpresaUsuario } from "../../../../store/actions/empresas";
import * as types from "../../../../store/types/modalidades";
import {
  EditAttributesOutlined,
  EditNotificationsOutlined,
  EditOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  KeyboardDoubleArrowLeftOutlined,
} from "@mui/icons-material";
import { loadModalidades } from "../../../../store/actions/modalidades";
import { useDispatch, useSelector } from "react-redux";
import { ActionCadatrarParceiroUsuario } from "../../../../store/actions/parceiros_integracao";

function ModalAddEmpresa({ open, close, updateList, parceiro_id }) {
  const [loading, setLoading] = useState(false);
  const [modalModalidades, setModalModalidades] = useState(false);
  const [dataModalInformations, setDataModalInformations] = useState({});
  const [data, setData] = useState({
    nome: "",
    email: "",
    senha: "",
  });

  const dispatch = useDispatch();
  const { modalidades, page, per_page, total } = useSelector(
    (state) => state.modalidades
  );
  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");

  async function GetDataLoad() {
    setLoading(true);
    const data = {
      page: page,
      per_page: per_page,
      filtro: filtroAtualizado,
      status: "A",
    };
    await loadModalidades(data);
    setLoading(false);

    setFiltroAtualizado(""); // Limpa o estado após a requisição
  }

  const navigatePages = (validations) => {
    dispatch({ type: types.MODALIDADES_DATA_PAGE, payload: validations });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, page]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, page]);

  const handleRowClick = (event, rowData) => {
    console.log(rowData);
    setDataModalInformations(rowData);
    setModalModalidades(false);
  };

  const [mesageErro, setMessageErro] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setMessageErro("");
    }, 3000);
  }, [mesageErro]);

  async function AddCompany() {
    setLoading(true);
    const _data = {
      nome: data.nome,
      email: data.email,
      password: data.senha,
      parceiros_integracao: +parceiro_id,
    };
    try {
      await ActionCadatrarParceiroUsuario(_data);
      updateList();
      setData({ nome: "", email: "", senha: "" });
      setLoading(false);
      close();
    } catch (e) {
      console.log(e.response.data.titulo);
      setMessageErro(e?.response?.data?.titulo);
      setLoading(false);
    }
  }

  const columns = [
    { title: "Id", render: ({ id }) => id },
    {
      title: "Modalidades de atendimento",
      render: ({ modalidade }) => modalidade,
    },
  ];

  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    margin: 15,
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };

  return (
    <ModalComponent open={open} width="50%">
      <Loading loading={loading} />
      <div style={{ padding: 15 }}>
        <h4>Insira os dados para incluir a usuário que fará atendimento</h4>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={4} sm={4}>
            <InputText
              type="text"
              value={data.nome ? data.nome : ""}
              onChange={(e) =>
                setData({
                  ...data,
                  nome: e.target.value
                    ? formatName(e.target.value)
                    : e.target.value,
                })
              }
              label="Nome usuário"
            />
          </Grid>

          <Grid item xs={5} sm={5}>
            <InputText
              type="email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              label="E-mail"
            />
          </Grid>
          <Grid item xs={3} sm={3}>
            <InputText
              type="text"
              value={data.senha}
              onChange={(e) => setData({ ...data, senha: e.target.value })}
              label="Senha"
            />
          </Grid>
        </Grid>
        <br />
        <ButtonComponent onClick={() => AddCompany()} fullWidth>
          Inserir usuario
        </ButtonComponent>
        <br />
        <ButtonComponent
          onClick={close}
          style={{ backgroundColor: "#3a3a3a" }}
          fullWidth
        >
          Fechar
        </ButtonComponent>

        <h4 style={{ color: "red", textAlign: "center" }}>{mesageErro}</h4>
      </div>

      <ModalComponent open={modalModalidades}>
        <Paper elevation={1} style={{ padding: 5 }}>
          <DataTable
            columns={columns}
            data={modalidades}
            enableHover={true}
            options={{
              defaultMessage: "Não há registros para serem exibidos.",
              rowStyle: { backgroundColor: "#ffffff", cursor: "pointer" },
              cellStyle: { border: "1px solid #ddd" },
            }}
            onRowClick={handleRowClick}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              fontFamily: "Poppins",
              fontSize: 14,
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{ marginRight: 30, display: "flex", flexDirection: "row" }}
            >
              {+page !== 1 && (
                <button
                  onClick={() => navigatePages(+page - 1)}
                  style={styledBtnArrows}
                >
                  <KeyboardDoubleArrowLeftOutlined /> Anterior{" "}
                </button>
              )}

              {modalidades?.length !== 0 && (
                <button
                  onClick={() => navigatePages(+page + 1)}
                  style={styledBtnArrows}
                >
                  Próximo <KeyboardArrowRightOutlined />
                </button>
              )}
            </div>
            <p>
              Pagina <b>{page}</b> | Total de registros <b>{total}</b>
            </p>
          </div>
        </Paper>

        <ButtonComponent
          onClick={() => setModalModalidades(false)}
          style={{ backgroundColor: "#3a3a3a" }}
          fullWidth
        >
          Fechar
        </ButtonComponent>
      </ModalComponent>
    </ModalComponent>
  );
}

export default ModalAddEmpresa;
