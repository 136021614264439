
import * as yup from "yup";

 const usuarioSchema = yup.object({
    endereco: yup.string().required("o nome da rua é obrigatório."),
    bairro: yup.string().required("o nome do bairro é obrigatório."),
    cidade: yup.string().required("o nome da cidade é obrigatório."),
    uf: yup.string().required("Informe a UF."),
    numero: yup.string().required("Digite o número."),
    celular: yup.string().required("Digite o celular."),
    nome: yup.string().required("O nome é obrigatório."),
    email: yup.string().email().required("Digite um email"),
    cpf: yup.string().required("Digite o CPF."),
    cep: yup.string().required("Digite o CEP."),
    sexo: yup.string().required("Selecione o Sexo."),
    estadoCivil: yup.string().required("Selecione o Estado Civil."),
    password: yup
      .string()
      .min(6, "Sua senha deve conter no mínimo 6 caracteres")
      .required("Digite sua senha para cadastro no App"),
  });

  export default usuarioSchema