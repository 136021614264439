import { Paper } from "@mui/material";
import { Modal } from "../../components";
import { formatCPF, formatCelular } from "../../helpers";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { Groups2Outlined } from "@mui/icons-material";
import { useState } from "react";
import formatName from "../../helpers/formatName";

function ModalUsuario({ open, data, close }) {
  const [openPartesEvolvidas, setOpenPartesEnvolvidas] = useState(false);

  const partesEnvolvidas =
    data.partes_envolvidas && JSON.parse(data.partes_envolvidas);

  console.log(partesEnvolvidas && partesEnvolvidas.length);

  const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
  `;

  const Tr = styled.tr`
    &:nth-child(even) {
      background-color: #f2f2f2;
    }
  `;

  const Td = styled.td`
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
  `;

  const Th = styled.th`
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: left;
    font-weight: bold;
    color: #565656;
    font-size: 13px;
  `;

  const ButtonParts = styled.button`
    display: flex;
    flex-direction: row;
    background-color: transparent;
    cursor: pointer;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    align-items: center;
    align-items: center;
    justify-content: space-around;
    color: #058471;
    width: 100%;
  `;
  const ButtonClose = styled.button`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border: none;
    font-family: Poppins;
    font-size: 12px;
    align-items: center;
    align-items: center;
    justify-content: space-around;
    background-color: #058471;
    color: #fff;
    width: 100%;
    border-radius: 7px;
    padding: 7px;
  `;

  async function OpenPartesEnvolvidas() {
    setOpenPartesEnvolvidas(true);
  }

  return (
    <Modal open={open} style={{ fontFamily: "Poppins" }} width="100%">
      <Paper elevation={1} style={{ padding: 5 }}>
        <Table style={{ border: "1px solid #dddd" }}>
          <thead>
            <Tr>
              <Th>Data início</Th>
              <Th>Data término</Th>
              <Th>Triagem</Th>
              <Th>Parceiro</Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td>{dayjs(data?.data_inicio).format("DD/MM/YYYY HH:mm")}</Td>
              <Td>{dayjs(data?.data_termino).format("DD/MM/YYYY HH:mm")}</Td>
              <Td>{data?.triagem_nome}</Td>
              <Td>{data?.parceiro_integracao_nome}</Td>
            </Tr>
          </tbody>
        </Table>
        <Table style={{ border: "1px solid #dddd" }}>
          <thead>
            <Tr>
              <Th>Nome</Th>
              <Th>CPF</Th>
              <Th>Telefone</Th>
              <Th>Email</Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td>{data?.nome_cliente}</Td>
              <Td>{data?.cpf_cliente && formatCPF(data?.cpf_cliente)}</Td>
              <Td>{data?.telefone && formatCelular(data?.telefone)}</Td>
              <Td>{data?.email}</Td>
            </Tr>
          </tbody>
        </Table>
        <Table style={{ border: "1px solid #dddd" }}>
          <thead>
            <Tr>
              <Th>Categoria Principal</Th>
              <Th>Categoria Secundaria</Th>
              <Th>Partes envolvidas</Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td>{data?.categoria_principal}</Td>
              <Td>{data?.categoria_secundaria}</Td>
              <Td>
                {partesEnvolvidas ? (
                  <ButtonParts onClick={() => OpenPartesEnvolvidas()}>
                    <Groups2Outlined />
                    &nbsp;{" "}
                    <span
                      style={{ textDecoration: "underline", color: "#058471" }}
                    >
                      Participantes
                    </span>
                  </ButtonParts>
                ) : (
                  <ButtonParts style={{ color: "gray", cursor: 'default' }}>
                    <Groups2Outlined />
                    &nbsp; <span>Não mencionado</span>
                  </ButtonParts>
                )}
              </Td>
            </Tr>
          </tbody>
        </Table>
        <Table style={{ border: "1px solid #dddd" }}>
          <thead>
            <Tr>
              <Th>Descrição Triagem</Th>
              <Th>Descrição Atendimento</Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td>
                <div style={{ height: 100, overflowY: "scroll" }}>
                  {data?.triagem_descricao}
                </div>
              </Td>
              <Td>
                <div style={{ height: 100, overflowY: "scroll" }}>
                  {data?.atendente_descricao}
                </div>
              </Td>
            </Tr>
          </tbody>
        </Table>
      </Paper>

      <div>
        <Modal open={openPartesEvolvidas} width="50%">
          <div style={{ height: 250, overflowY: "scroll" }}>
            <Table style={{ border: "1px solid #dddd" }}>
              <thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Documento</Th>
                  <Th>Tipo</Th>
                </Tr>
              </thead>
              <tbody>
                {partesEnvolvidas &&
                  partesEnvolvidas.map((partes) => {
                    return (
                      <Tr>
                        <Td>{formatName(partes?.nome)}</Td>
                        <Td>{partes?.documento}</Td>
                        <Td>{partes?.tipo}</Td>
                      </Tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
          <ButtonClose onClick={() => setOpenPartesEnvolvidas(false)}>
            {" "}
            FECHAR PARTICIPANTES
          </ButtonClose>
        </Modal>
      </div>
      <ButtonClose onClick={close}>FECHAR</ButtonClose>
    </Modal>
  );
}

export default ModalUsuario;
