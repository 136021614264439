import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DataTable } from "../../components";
import { Paper, Tooltip } from "@mui/material";
import MaskCPFCNPJ from "../../helpers/maskCpfCnpj";
import {
  AccessTime,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  QuestionAnswerOutlined,
} from "@mui/icons-material";
import ButtonComponent from "../../components/ButtonComponent";
import ModalAddEmpresa from "./cadastro/add";
import * as types from "../../store/types/modalidades";
import Loading from "../../components/Loading";
import { LoadHorariosDeAtendimentoPorModalide, loadModalidades } from "../../store/actions/modalidades";
import { useNavigate } from "react-router-dom";
import DefinirHorarios from "./cadastroHorario";

function ModalidadesApp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalidades, page, per_page, total } = useSelector(
    (state) => state.modalidades
  );
  const [filtro, setFiltro] = useState("");
  const [filtroAtualizado, setFiltroAtualizado] = useState("");
  const [openModalInformations, setOpenModalInformations] = useState(false);
  const [modalOpenAdd, setModalOpenAdd] = useState(false);
  const [openModalHorarios, setOpenModalHorarios] = useState(false);
  const [modalidadeId, setModalidadeId] = useState()
  const [loading, setLoading] = useState(false);

  async function GetDataLoad() {
    setLoading(true);
    const data = {
      page: page,
      per_page: per_page,
      filtro: filtroAtualizado,
      status: "A",
    };
    await loadModalidades(data);
    setLoading(false);

    setFiltroAtualizado(""); // Limpa o estado após a requisição
  }

  const navigatePages = (validations) => {
    dispatch({ type: types.MODALIDADES_DATA_PAGE, payload: validations });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtro === "") {
        GetDataLoad(); // Se filtro está vazio, chama a função GetDataLoad diretamente
      } else {
        setFiltroAtualizado(filtro);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [filtro, page]);

  useEffect(() => {
    if (filtroAtualizado !== "") {
      GetDataLoad();
    }
  }, [filtroAtualizado, page]);


  const styledBtnArrows = {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    border: "none",
    cursor: "pointer",
    margin: 15,
    backgroundColor: "transparent",
    fontFamily: "Poppins",
  };


  async function OpenModalHorariosCadastrados(data) {
    setModalidadeId(data);
    try {
      await LoadHorariosDeAtendimentoPorModalide(data.id);
      setOpenModalHorarios(true);
    } catch (e) {
      console.log(e);
    }
  }


  const columns = [
    { title: "Id", render: ({ id }) => id },
    {
      title: "Modalidades de atendimento criados",
      render: ({ modalidade }) => modalidade,
    },    
    {
      title: "Ação",
      render: (data) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <button onClick={() => handleRowClick(data)}>

            <h5 style={{ marginRight: 10 }}>Questionário</h5>
            </button>
            <QuestionAnswerOutlined fontSize="12px" />

            <Tooltip title="Horários de Atendimento">
                <button
                  style={{
                    fontSize: 25,
                    marginLeft: 15,
                  }}
                  onClick={() => OpenModalHorariosCadastrados(data)}
                >
                  <AccessTime style={{ color: "#3992EE" }} />
                </button>
              </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleRowClick = (rowData) => {
    navigate(`/app/modalidades/${rowData.id}`);
    dispatch({ type: types.MODALIDADE_SELECIONADA_USUARIOS, payload: rowData });
    setOpenModalInformations(true);
  };

  return (
    <div>
      <Loading loading={loading} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h4>Modalidades de atendimento</h4>
        <ButtonComponent onClick={() => setModalOpenAdd(true)}>
          Adicionar modalidades
        </ButtonComponent>
      </div>
      <Paper elevation={1} style={{ padding: 5 }}>
        <DataTable
          columns={columns}
          data={modalidades}
          enableHover={true}
          options={{
            defaultMessage: "Não há registros para serem exibidos.",
            rowStyle: { backgroundColor: "#ffffff", cursor: "pointer" },
            cellStyle: { border: "1px solid #ddd" },
          }}
          // onRowClick={handleRowClick}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            fontFamily: "Poppins",
            fontSize: 14,
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{ marginRight: 30, display: "flex", flexDirection: "row" }}
          >
            {+page !== 1 && (
              <button
                style={styledBtnArrows}
                onClick={() => navigatePages(+page - 1)}
              >
                <KeyboardArrowLeftOutlined /> Anterior{" "}
              </button>
            )}

            {modalidades?.length !== 0 && (
              <button
                style={styledBtnArrows}
                onClick={() => navigatePages(+page + 1)}
              >
                Próximo <KeyboardArrowRightOutlined />
              </button>
            )}
          </div>
          <p>
            Pagina <b>{page}</b> | Total de registros <b>{total}</b>
          </p>
        </div>
      </Paper>

      <ModalAddEmpresa
        open={modalOpenAdd}
        close={() => setModalOpenAdd(false)}
        updateList={() => GetDataLoad()}
      />


<DefinirHorarios
          open={openModalHorarios}
          close={() => setOpenModalHorarios(false)}
          data={modalidadeId}
        />
    </div>
  );
}

export default ModalidadesApp;
