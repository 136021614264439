import { Divider, Grid, Tooltip } from "@mui/material";

function PartesEnvolvidasAtendimento({ usuarios }) {
  return (
    <div style={{ height: "100%", overflowY: "scroll" }}>
      {usuarios?.length > 0 && (
        <>
          <h4>Partes envolvidas</h4>
          <Grid
            container
            spacing={1}
            style={{ fontSize: 12, fontWeight: 600, color: "#191919" }}
          >
            <Grid item xs={4} sm={4}>
              Nome
            </Grid>
            <Grid item xs={4} sm={4}>
              Documento
            </Grid>
            <Grid item xs={4} sm={4}>
              Tipo
            </Grid>
          </Grid>
          <div style={{ padding: 15, marginBottom: 20 }}>
         
                {usuarios.map((user, index) => (
                  <Grid
                    container
                    spacing={1}
                    key={index}
                    style={{ fontSize: 14 }}
                  >
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      style={{
                        maxWidth: "30ch",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Tooltip title={user.nome}>
                        <div>{user.nome}</div>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      {user.documento}
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      {user.tipo}
                    </Grid>
                    <Divider />
                  </Grid>
                ))}
           
        
          </div>
        </>
      )}
    </div>
  );
}

export default PartesEnvolvidasAtendimento;
