import { Divider, Grid, Paper, TextField } from "@mui/material";
import { InputText, Modal } from "../../../../../components";
import ButtonComponent from "../../../../../components/ButtonComponent";
import { useEffect, useState } from "react";
import ModalComponent from "../../../../../components/MDModal";
import formatName from "../../../../../helpers/formatName";
import Loading from "../../../../../components/Loading";
import {   ActionCadatrarModalidadesCategoriaSecundaria } from "../../../../../store/actions/modalidades";

function ModalAdd({ open, close, updateList,  dataCategoria}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({  categoria_secundaria: "",  });

  const [mesageErro, setMessageErro] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setMessageErro("");
    }, 3000);
  }, [mesageErro]);

  async function AddCompany() {
    setLoading(true);
    const _data = {
      categoria_secundaria: data.categoria_secundaria, 
      modalidade_id: dataCategoria.id
    };
    try {
      await ActionCadatrarModalidadesCategoriaSecundaria(_data);
      updateList();
      setData({ modalidade: ""});
      setLoading(false);
      close();
    } catch (e) {
      console.log(e.response.data.titulo);
      setMessageErro(e?.response?.data?.titulo);
      setLoading(false);
    }
  }

  return (
    <ModalComponent open={open} width="30%">
      <Loading loading={loading} />
      <div style={{ padding: 15 }}>
        <h4>Categoria principal: {dataCategoria.categoria_principal}</h4>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <InputText
              type="text"
              value={data.categoria_secundaria ? data.categoria_secundaria : ""}
              onChange={(e) =>
                setData({
                  ...data,
                  categoria_secundaria: e.target.value
                    ? formatName(e.target.value)
                    : e.target.value,
                })
              }
              label="Categoria Secundária"
            />
          </Grid>
    
        </Grid>
        <br />
        <ButtonComponent onClick={() => AddCompany(200)} fullWidth>
          Inserir categoria
        </ButtonComponent>
        <br />
        <ButtonComponent
          onClick={close}
          style={{ backgroundColor: "#3a3a3a" }}
          fullWidth
        >
          Fechar
        </ButtonComponent>

        <h4 style={{ color: "red", textAlign: "center" }}>{mesageErro}</h4>
      </div>
    </ModalComponent>
  );
}

export default ModalAdd;
