import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  PlaylistPlay as PlaylistPlayIcon,
  PlaylistRemove as PlaylistRemoveIcon,
  FormatListBulletedOutlined as TableBar,
  StoreOutlined as BusinessIcon,
  GroupOutlined as GrupoIcon,
  AddIcCallOutlined as ChamadaIcon,
  CallEndOutlined as ChamadaEncerradaIcon,
  CalendarTodayOutlined as CalendarIcon,
  Webhook as APIIcon,
  PhoneCallbackOutlined as PhoneCallIcon,
} from "@mui/icons-material";

function MenuListagem() {
  const permissao = localStorage.getItem("permissao");


  const MENU_LIST = [
    {
      route: "/app/home",
      literal: "Atendimentos",
      Icon: ChamadaIcon,
      adminOnly: false,
      integracaoOnly: false,
    },
    {
      route: "/app/atendimento-aberto",
      literal: "Atendimentos abertos",
      Icon: PhoneCallIcon,
      adminOnly: false,
      integracaoOnly: false,
    },
    {
      route: "/app/atendimento/finalizados",
      literal: "Atendimentos finalizados",
      Icon: ChamadaEncerradaIcon,
      adminOnly: true,
      integracaoOnly: true,
    },
    {
      route: "/app/modalidades",
      literal: "Modalidades",
      Icon: CalendarIcon,
      adminOnly: true,
      integracaoOnly: false,
    },
    {
      route: "/app/empresas",
      literal: "Empresas",
      Icon: BusinessIcon,
      adminOnly: true,
      integracaoOnly: false,
    },
    {
      route: "/app/parceiros",
      literal: "Parceiros Integração",
      Icon: APIIcon,
      adminOnly: true,
      integracaoOnly: false,
    },
    {
      route: "/app/usuarios",
      literal: "Usuários",
      Icon: GrupoIcon,
      adminOnly: true,
      integracaoOnly: false,
    },
  ];

  if (permissao === "ADMINISTRADOR") {
    return MENU_LIST.filter(item => item.adminOnly);
  } else if (permissao === "INTEGRACAO") {
    return MENU_LIST.filter(item => item.integracaoOnly);
  } else {
    return MENU_LIST.filter(item => !item.adminOnly && !item.integracaoOnly);
  }
}

export default MenuListagem;
