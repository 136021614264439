import Participant from "./Participant";
import styled from "@emotion/styled";
import { useRoom } from "../../hooks/useTwilioVideo/index";
import { useEffect } from "react";
import { CircularProgress, Grid } from "@mui/material";
import {
  BiCameraOff,
  BiCamera,
  BiMicrophoneOff,
  BiMicrophone,
} from "react-icons/bi";
import { MdOutlineCallEnd } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function Room({ token, cancelar, room,
  connectRoom,
  disconnectRoom,
  localParticipant,
  remoteParticipants,
  isCameraOn,
  toggleCamera,
  isMicrophoneOn,
  toggleMicrophone }) {
  const navigate = useNavigate();
 

  useEffect(() => {
    if (!room && token) {
      connectRoom({ token, options: { dominantSpeaker: true } });
      return () => disconnectRoom();
    }
  }, [connectRoom, disconnectRoom, room, token]);



  const Button = styled.button`
    border-radius: 50%;
    cursor: pointer;
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: none;
    margin: 15px;
    box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.72);
  `;

  if (!room) {
    return <div>Nenhuma sala disponível</div>;
  }


  return (
    <div style={{ height: "100%" }}>
      <Grid container>
        <Grid sm={6}>
          <div
            style={{
              width: 160,
              height: 280,
              overflow: "hidden",
              borderRadius: "10px",
              margin: "auto",
              background: "#191911",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Participant participant={localParticipant} />
          </div>
        </Grid>
        <Grid sm={6}>
          {remoteParticipants.length > 0 ? (
            <div
              style={{
                width: 160,
                height: 280,
                overflow: "hidden",
                borderRadius: "10px",
                margin: "auto",
                background: "#191911",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Participant
                key={remoteParticipants[0]?.sid}
                participant={remoteParticipants[0]}
              />
            </div>
          ) : (
            <div
              style={{
                width: 160,
                height: 280,
                overflow: "hidden",
                borderRadius: "10px",
                background: "#191911",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
            
                <div>
                  <CircularProgress color="info" />
                </div>
                <div style={{ color: "#fff", textAlign: 'center', fontSize: 12 }}>
                  Aguarde, sua chamada será iniciada em instantes{" "}
                </div>
            </div>
          )}

          {/* {remoteParticipants.map((p) => (
              <Participant key={p.sid} participant={p} />
            ))} */}
        </Grid>
      </Grid>
      <div style={{ textAlign: "center" }}>
        <Button onClick={() => toggleCamera()}>
          {isCameraOn ? <BiCamera size={24} /> : <BiCameraOff size={24} />}
        </Button>
        {/* <Button
          onClick={() => CancelAtendimento()}
          style={{ backgroundColor: "red", color: "#fff" }}
        >
          <MdOutlineCallEnd size={24} />
        </Button> */}
        <Button onClick={() => toggleMicrophone()}>
          {isMicrophoneOn ? (
            <BiMicrophone size={24} />
          ) : (
            <BiMicrophoneOff size={24} />
          )}
        </Button>
      </div>
    </div>
  );
}

export default Room;
