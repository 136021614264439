import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#42b5e6",
    },
    secondary: {
      main: "#6750a4",
    },
  },
});

export default theme