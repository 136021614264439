export const USUARIOS_LOADING = 'USUARIOS_LOADING';
export const USUARIOS_DATA = 'USUARIOS_DATA';
export const USUARIO_DATA = 'USUARIO_DATA';
export const USUARIOS_CLIENTES_DATA = 'USUARIOS_CLIENTES_DATA';
export const USUARIOS_SELECIONADA_DATA = 'USUARIOS_SELECIONADA_DATA';


export const USUARIOS_DATA_PAGE = "USUARIOS_DATA_PAGE";
export const USUARIOS_DATA_PER_PAGE = "USUARIOS_DATA_PER_PAGE";
export const USUARIOS_DATA_TOTAL = "USUARIOS_DATA_TOTAL";

