import * as types from "../types/usuarios";

const INITIAL_STATE = {
  loading: false,
  usuarios: [],
  usuario: {},
  page: 1,
  per_page: 10,
  total: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.USUARIOS_LOADING:
      return { ...state, loading: action.payload };

    case types.USUARIOS_DATA:
      return { ...state, usuarios: action.payload };

    case types.USUARIO_DATA:
      return { ...state, usuario: action.payload };


    case types.USUARIOS_DATA_PAGE:
      return { ...state, page: action.payload };

    case types.USUARIOS_DATA_PER_PAGE:
      return { ...state, per_page: action.payload };

    case types.USUARIOS_DATA_TOTAL:
      return { ...state, total: action.payload };

   
    default:
      return state;
  }
};
