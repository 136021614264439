import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModalComponent from "../../../components/MDModal";
import ButtonComponent from "../../../components/ButtonComponent";
import { CadastrarHorariosDeAtendimentoPorModalidade } from "../../../store/actions/modalidades";

function DefinirHorarios({ open, close, data }) {
  const { horariosModalidades } = useSelector((state) => state.modalidades);

  const [horariosAtendimento, setHorariosAtendimento] = useState([]);

  function CloseModalFnc() {
    // setHorariosAtendimento([])
    close();
  }

  const formatarHorariosCadastr = horariosAtendimento.map((horario) => {
    return {
      dia_semana:
        horario.dia_semana === 1
          ? "domingo"
          : horario.dia_semana === 2
          ? "segunda_feira"
          : horario.dia_semana === 3
          ? "terca_feira"
          : horario.dia_semana === 4
          ? "quarta_feira"
          : horario.dia_semana === 5
          ? "quinta_feira"
          : horario.dia_semana === 6
          ? "sexta_feira"
          : "sabado",

      hora_inicio: horario.hora_inicio,
      hora_termino: horario.hora_termino,
      aberto: horario.aberto ? true : false,
    };
  });

  async function CadastrarHorarios() {
    const dataCadastro = { horarios: formatarHorariosCadastr };
    try {
      await CadastrarHorariosDeAtendimentoPorModalidade(data.id, dataCadastro);
      close();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (horariosModalidades.length === 0) {
      // Se não houver horários, preencha todos os dias com "00:00"
      const horariosPadrao = Array.from({ length: 7 }, (_, index) => ({
        id: index + 1,
        dia_semana: index + 1,
        hora_inicio: "00:00",
        hora_termino: "00:00",
        aberto: 0,
        wl_parceiros_categorias: 1,
      }));
      setHorariosAtendimento(horariosPadrao);
    } else {
      setHorariosAtendimento(horariosModalidades);
    }
  }, [horariosModalidades]);

  const handleChange = (diaSemana, field, value) => {
    const novosHorarios = horariosAtendimento.map((horario) => {
      if (horario.dia_semana === diaSemana) {
        return {
          ...horario,
          [field]: value,
        };
      }
      return horario;
    });
    setHorariosAtendimento(novosHorarios);
  };

  return (
    <ModalComponent open={open} width="40%">
      <span>
        Defina os horários de atendimento para <b>{data?.categoria}</b>
      </span>
      <Divider />
      <table style={{ borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ fontSize: 12, textAlign: "left" }}>
            <th style={{ width: "25%" }}>Dia</th>
            <th style={{ width: "30%" }}>Horário de Início</th>
            <th style={{ width: "30%" }}>Horário de Término</th>
            <th style={{ width: "15%" }}>Aberto</th>
          </tr>
        </thead>
        <tbody>
          {horariosAtendimento &&
            horariosAtendimento.map((horario) => (
              <tr key={horario.id}>
                <td style={{ fontSize: 14 }}>
                  {horario.dia_semana === 1
                    ? "Domingo"
                    : horario.dia_semana === 2
                    ? "Segunda Feira"
                    : horario.dia_semana === 3
                    ? "Terça Feira"
                    : horario.dia_semana === 4
                    ? "Quarta Feira"
                    : horario.dia_semana === 5
                    ? "Quinta Feira"
                    : horario.dia_semana === 6
                    ? "Sexta Feira"
                    : "Sábado"}
                </td>

                <td>
                  <input
                    type="time"
                    value={horario.hora_inicio}
                    onChange={(e) =>
                      handleChange(horario.dia_semana, "hora_inicio", e.target.value)
                    }
                    disabled={horario.aberto === 0}
                  />
                </td>
                <td>
                  <input
                    type="time"
                    value={horario.hora_termino}
                    onChange={(e) =>
                      handleChange(horario.dia_semana, "hora_termino", e.target.value)
                    }
                    disabled={horario.aberto === 0}
                  />
                </td>
                <td style={{ fontSize: 12, fontWeight: "bold" }}>
                  <input
                    type="checkbox"
                    checked={horario.aberto === 1}
                    onChange={(e) =>
                      handleChange(horario.dia_semana, "aberto", e.target.checked ? 1 : 0)
                    }
                  />
                  &nbsp;{" "}
                  {horario.aberto ? (
                    <span style={{ color: "green" }}>Aberto</span>
                  ) : (
                    <span style={{ color: "red" }}>Fechado</span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div style={{ marginTop: 15 }}>
        <ButtonComponent variant="gradient" color="info" fullWidth onClick={() => CadastrarHorarios()}>
          Salvar Alterações
        </ButtonComponent>
        <br/>
        <ButtonComponent variant="gradient" color="primary" style={{marginTop: 15}} fullWidth onClick={() => CloseModalFnc()}>
        Fechar
        </ButtonComponent>

      </div>
    </ModalComponent>
  );
}

export default DefinirHorarios;
