import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { MdGroupAdd } from "react-icons/md";
import { InputText } from "../../components";

function PartesEnvolvidas({
  novoUsuario,
  setNovoUsuario,
  usuarios,
  setUsuarios,
}) {
  const adicionarUsuario = () => {
    // Verifica se o usuário já foi adicionado
    if (!usuarios.some((user) => user.nome === novoUsuario.nome)) {
      setUsuarios([...usuarios, novoUsuario]);
    }

    // Limpa os campos após adicionar o usuário
    setNovoUsuario({
      nome: "",
      documento: "",
      tipo: "Requerente", // Defina o valor padrão para 'Requerente' ou outro valor desejado
    });
  };

  const removerUsuario = (index) => {
    const novosUsuarios = [...usuarios];
    novosUsuarios.splice(index, 1);
    setUsuarios(novosUsuarios);
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={4} sm={4}>
          <InputText
            type="text"
            placeholder="Nome"
            label="Nome"
            value={novoUsuario.nome}
            onChange={(e) =>
              setNovoUsuario({
                ...novoUsuario,
                nome: e.target.value.toUpperCase(),
              })
            }
          />
        </Grid>
        <Grid item xs={3} sm={3}>
          <InputText
            type="text"
            label="Documento"
            placeholder="Documento"
            value={novoUsuario.documento}
            onChange={(e) =>
              setNovoUsuario({ ...novoUsuario, documento: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={4} sm={4}>
          <FormControl>
            <InputLabel style={{ background: "#fff", padding: "0px 5px" }}>
              Tipo
            </InputLabel>
            <Select
              value={
                novoUsuario?.tipo?.length > 0 ? novoUsuario.tipo : "Selecione"
              }
              onChange={(e) =>
                setNovoUsuario({ ...novoUsuario, tipo: e.target.value })
              }
              style={{ height: "40px" }}
            >
              <MenuItem value="Selecione" disabled>
                Selecione
              </MenuItem>
              <MenuItem value="Requerente">Requerente</MenuItem>
              <MenuItem value="Requerido">Requerido</MenuItem>
              <MenuItem value="Testemunha">Testemunha</MenuItem>
              <MenuItem value="Outros">Outros</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} sm={1}>
          <button
            style={{
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
            onClick={adicionarUsuario}
          >
            <MdGroupAdd size={24} />
          </button>
        </Grid>
      </Grid>

      <div style={{height: 222, overflowY: 'scroll'}}>
        {usuarios?.length > 0 && (
          <>
            <h4>Usuários Adicionados</h4>
            <Grid container spacing={1} style={{fontSize: 12, fontWeight: 600, color: "#191919"}}>
              <Grid item xs={4} sm={4}>
                Nome
              </Grid>
              <Grid item xs={3} sm={3}>
                Documento
              </Grid>
              <Grid item xs={3} sm={3}>
                Tipo
              </Grid>
              <Grid item xs={2} sm={2}>
                Ação
              </Grid>
            </Grid>
            <div style={{padding: 15, marginBottom: 20}}>
            {usuarios.map((user, index) => (
              <Grid container spacing={1} key={index} style={{fontSize: 14}}>
                <Grid
                  item
                  xs={4}
                  sm={4}
                  style={{
                    maxWidth: "30ch",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Tooltip title={user.nome}>
                    <div>{user.nome}</div>
                  </Tooltip>
                </Grid>
                <Grid item xs={3} sm={3}>
                  {user.documento}
                </Grid>
                <Grid item xs={3} sm={3}>
                  {user.tipo}
                </Grid>
                <Grid item xs={2} sm={2}>
                  <button onClick={() => removerUsuario(index)}>Remover</button>
                </Grid>
                <Divider />
              </Grid>
            ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PartesEnvolvidas;
