import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent";
import * as types from '../../store/types/triagem'
import {
  EntarTriagemAction,
  EntrarAtendimentoAction,
  StatusPausaTriagem,
} from "../../store/actions/triagem";
import SwitchIOS from "./switchOnEOff";
import http from "../../services/api";

import formatName from "../../helpers/formatName";
import SwitchPausaratendimento from "./switchPausarAtendimento";
import calcularTempoEmEspera from "./calcularTempoEspera";
import usePageVisibility from "../../helpers/pageVisible";
import dayjs from "dayjs";

function HomeScreen() {
  const dispatch = useDispatch()
  const isVisible = usePageVisibility();
  const { atendimentosAbertos } = useSelector((state) => state.triagem);
  const [playSound, setPlaySound] = useState(false); // Estado para controlar a reprodução do som
  const navigate = useNavigate();
  const { usuario, permissoes } = useSelector((state) => state.usuario);
  const { horariosAtendimentoTriagem } = useSelector((state) => state.triagem);
  const [dataSocket, setDataSocket] = useState("");
  const [loading, setLoading] = useState("");

  const styleBtn = {
    border: "none",
    borderRadius: 7,
    cursor: "pointer",
    background: "transparent",
    textDecoration: "underline",
  };

  async function EntarChamadaTriagem(data) {
    setLoading(data);
    try {
      if (permissoes.tipo === "TRIAGEM") {
        await EntarTriagemAction({ sala: data.sala }).then((res) => {
          localStorage.setItem("atendimento", "OCUPADO");
          dispatch({ type: types.SET_PLAY_SONG, payload: false })
          navigate(`/app/home/${res.sala}`, { replace: true });
          setLoading("");
        });
      } else if (permissoes.tipo === "ATENDIMENTO") {
        await EntrarAtendimentoAction({ sala: data.sala }).then((res) => {
          navigate(`/app/home/atendimento/${res.sala}`, { replace: true });
          setLoading("");
        });
      } else {
        throw setLoading("");
      }
    } catch (e) {
      console.log(e);
      setLoading("");
    }
  }

  async function updateStatusInDatabase(newStatus) {
    await http
      .put("/api/v1/atendimento/atualizar-status", {
        statusOperacional: newStatus,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }


  async function UpdateStatusPausaAtendimento(newStatus) {
    await http
      .put("/api/v1/atendimento/horarios/pausar", {
        validation: newStatus,
      })
      .then((res) => {
        StatusPausaTriagem();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    StatusPausaTriagem();
  }, []);






  return (
    <div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h1>Atendimentos pendentes</h1>
        {permissoes.tipo === "ATENDIMENTO" && (
          <SwitchIOS
            onUpdateStatus={updateStatusInDatabase}
            data={permissoes}
          />
        )}

        <>
          {permissoes.tipo === "TRIAGEM" && (
            <SwitchPausaratendimento
              onUpdateStatus={UpdateStatusPausaAtendimento}
              data={horariosAtendimentoTriagem}
            />
          )}
        </>
      </div>
      <Grid container spacing={1}>
        {atendimentosAbertos?.map((at, index) => {
          return (
            <Grid sm={4} key={index.id}>
              <div style={{ padding: 15 }}>
                <div
                  style={{
                    padding: 15,
                    border: "1px solid #191919",
                    borderRadius: 7,
                  }}
                >
                  <b>Cliente:</b> {at?.nome} <br />
                  <b>Modalidade:</b> {at?.modalidade} <br />
                  <b>Parceiro Integrado:</b>{" "}
                  {formatName(at?.parceiro_integracao_nome)} <br />
                  <b>Tempo de espera:</b>{" "}
                  {calcularTempoEmEspera(dayjs(at?.data_inicio).add('3', 'hour'))}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 15,
                    }}
                  >
                    {at.sala !== loading.sala ? (
                      <ButtonComponent onClick={() => EntarChamadaTriagem(at)}>
                        Entrar na chamada
                      </ButtonComponent>
                    ) : (
                      <div
                        style={{
                          background: "#2e871b",
                          border: "none",
                          color: "#fff",
                          fontFamily: "Poppins",
                          fontSize: 12,
                          fontWeight: 500,
                          flexDirection: "row",
                          display: "flex",
                          padding: "15px 25px",
                          cursor: "pointer",
                          borderRadius: 9,
                          width: "100%",
                          textAlign: "center",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress
                          style={{ color: "#fff", width: 15, height: 15 }}
                        />
                        &nbsp; Aguarde, estamos iniciando!
                      </div>
                    )}
                    <button
                      style={{ ...styleBtn }}
                      onClick={() =>       dispatch({ type: types.SET_PLAY_SONG, payload: false })}
                    >
                      Silenciar chamada
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>

 
    </div>
  );
}

export default HomeScreen;
