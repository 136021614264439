import React, { useState, useEffect } from "react";
import http from "../../services/api";
import { Select, MenuItem, FormControl, InputLabel, Grid } from "@mui/material";

function CategoriaAtendimentoSelecionada({
  modalidadeId,
  categoriaSecundariaSelecionada,
  setCategoriaSecundariaSelecionada,
  data,
}) {
  const [categoriasPrincipaisLoad, setCategoriasPrincipaisLoad] = useState([]);
  const [categoriasSecundarias, setLoadCategoriasSecundarias] = useState([]);
  const [categoriaPrincipalSelecionada, setCategoriaPrincipalSelecionada] =
    useState("");

  const [categoriasSecundariasFiltradas, setCategoriasSecundariasFiltradas] =
    useState([]);

  const handleCategoriaPrincipalChange = (event) => {
    const categoriaPrincipalId = event.target.value;
    setCategoriaPrincipalSelecionada(categoriaPrincipalId);
    setCategoriasSecundariasFiltradas([]);

    // Filtrar as categorias secundárias com base na categoria principal selecionada
    const categoriasFiltradas = categoriasSecundarias.filter(
      (categoria) => categoria.categoria_principal_id === +categoriaPrincipalId
    );

    setCategoriasSecundariasFiltradas(categoriasFiltradas);

    // Resetar a categoria secundária selecionada
    setCategoriaSecundariaSelecionada("");
  };


  useEffect(() => {
    async function GetCategoriasAtendimento() {
      try {
        const response = await http.get(
          `/api/v1/categorias-atendimento/listar?modalidade_id=${data.modalidade_id}`
        );
        const categoriaPrincipalData = response.data.categoria_principal;
        const categoriaSecundariaData = response.data.categorias;

        setCategoriasPrincipaisLoad(categoriaPrincipalData);
        setLoadCategoriasSecundarias(categoriaSecundariaData);

        const carregarCategoriasJaSelecionadasPrincipal =
          categoriaPrincipalData.find(
            (categoria) => categoria.id === data.categoria_principal_id
          );
        const carregarCategoriasJaSelecionadasSecundaria =
          categoriaSecundariaData.find(
            (categoria) =>
              categoria.categoria_secundaria_id === data.categoria_secundaria
          );

        if (carregarCategoriasJaSelecionadasPrincipal.id) {
          const categoriasFiltradas = categoriaSecundariaData.filter(
            (categoria) =>
              categoria.categoria_principal_id ===
              +carregarCategoriasJaSelecionadasPrincipal.id
          );

          setCategoriasSecundariasFiltradas(categoriasFiltradas);
        }

        setCategoriaPrincipalSelecionada(
          carregarCategoriasJaSelecionadasPrincipal.id
        );
        setCategoriaSecundariaSelecionada(
          carregarCategoriasJaSelecionadasSecundaria.categoria_secundaria_id
        );
      } catch (error) {
        console.error("Erro ao obter categorias", error);
      }
    }

    GetCategoriasAtendimento();
  }, [modalidadeId]);

  return (
    <div style={{ width: "80%", }}>
      <Grid
        container
        spacing={1}
        style={{ fontSize: 12, fontWeight: 600, color: "#191919" }}
      >
        <Grid item xs={6} sm={6}>
          <FormControl fullWidth>
            Categoria Principal
            <Select
              value={categoriaPrincipalSelecionada}
              onChange={handleCategoriaPrincipalChange}
              style={{ height: "40px" }}
            >
              {categoriasPrincipaisLoad.map((categoria) => (
                <MenuItem key={categoria.id} value={categoria.id}>
                  {categoria.categoria_principal}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={6}>
          {categoriaPrincipalSelecionada && (
            <div>
              <FormControl fullWidth>
                Categoria Secundária
                <Select
                  value={categoriaSecundariaSelecionada}
                  style={{ height: "40px" }}
                  onChange={(event) =>
                    setCategoriaSecundariaSelecionada(event.target.value)
                  }
                >
                  {categoriasSecundariasFiltradas.map((categoria, index) => (
                    <MenuItem
                      key={index.categoria_secundaria_id}
                      value={categoria.categoria_secundaria_id}
                    >
                      {categoria.categoria_secundaria}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default CategoriaAtendimentoSelecionada;
