import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";

const Loading = (props) => {
  return (
    <Backdrop
      style={{ color: "#8FC18A", background: "rgba(0,0,0,0.1)" }}
      open={props.loading}
    >
     <CircularProgress />
    </Backdrop>
  );
};

export default Loading;
