import { Divider, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent";
import formatName from "../../helpers/formatName";
import {
  EntrarAtendimentoAction,
  FinalizarChamadaAtendimento,
  ListarAtendentesOnlineAction,
  ListarAtendimentoPorSalaAction,
  TransferirChamadaAction,
} from "../../store/actions/triagem";
import { CameraVideo } from "./style";
import PartesEnvolvidasAtendimento from "./partesEnvolvidas";
import Room from "../../components/VideoCall/Room";
import { useRoom } from "../../hooks/useTwilioVideo";

function ChamadaAtendimentoRoute() {
  const navigate = useNavigate();

  const {
    room,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone,
  } = useRoom();

  const { urlRoom } = useParams();
  const { atendentesOnline } = useSelector((state) => state.triagem);

  const [dataRoom, setDataRoom] = useState({});
  const [descricao, setDescricao] = useState("");
  const [criarDescricao, setCriarDescricao] = useState(false);
  const [atendenteSelected, setAtendenteSelected] = useState(false);
  const [atendenteSelectedData, setAtendenteSelectedData] = useState({});

  const [modalAtendente, setModalAtendente] = useState(false);

  async function ListagemAtendimentosSala() {
    await ListarAtendimentoPorSalaAction(urlRoom).then((res) => {
      setDataRoom(res);
    });
  }

  console.log(dataRoom)

  useEffect(() => {
    if (dataRoom) {
      setDescricao(dataRoom.atendente_descricao);
    }
  }, [dataRoom]);

  useEffect(() => {
    ListagemAtendimentosSala();
  }, []);

  async function EntarChamadaTriagem(data) {
    try {
      await EntrarAtendimentoAction({
        sala: urlRoom,
        atendente_descricao: descricao,
      });
      await ListagemAtendimentosSala();
      setCriarDescricao(false);
    } catch (e) {
      console.log(e);
    }
  }



  async function CancelarAtendimento() {
    console.log("cancelar atendimento");
  }

  async function FinalizarChamada(){
    try{
      await FinalizarChamadaAtendimento({sala: dataRoom.sala})
      disconnectRoom()
      navigate("/app/home");
    } catch(e){
      console.log(e)
    }
  }

  const colorBtn = {
    border: "none",
    color:  "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight:  500 ,
    flexDirection: "row",
    display: "flex",
    padding: "15px 25px",
    cursor: "pointer",
    borderRadius: 9,
    width:  "100%",
    textAlign: 'center',
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center"
  }

  return (
    <div style={{ height: "100%", marginBottom: 500 }}>
      <Paper elevation={4} style={{ marginBottom: 30 }}>
        <Grid container spacing={1} style={{ padding: 15 }}>
          <Grid item xs={5} sm={5}>
            <div>
              Cliente: <b> {formatName(dataRoom.nome)}</b> <br />
              Modalidade: <b>{formatName(dataRoom.modalidade)}</b>
            </div>
          </Grid>
          <Grid item xs={5} sm={5}>
            <div>
              Categoria principal:{" "}
              <b>{formatName(dataRoom.categoria_principal_descricao)}</b> <br />
              Categoria secundária:{" "}
              <b>{formatName(dataRoom.categoria_secundaria_descricao)}</b>
            </div>
          </Grid>
          <Grid item xs={2} sm={2}>
          <button style={{...colorBtn, backgroundColor: 'red'}}  onClick={() => FinalizarChamada()}>
              Finalizar chamada
            </button>
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={1}>
        <Grid item xs={4} sm={4}>
          <Paper elevation={3}>
            <Room
              token={dataRoom.token}
              cancelar={CancelarAtendimento}
              room={room}
              connectRoom={connectRoom}
              disconnectRoom={disconnectRoom}
              localParticipant={localParticipant}
              remoteParticipants={remoteParticipants}
              isCameraOn={isCameraOn}
              toggleCamera={toggleCamera}
              isMicrophoneOn={isMicrophoneOn}
              toggleMicrophone={toggleMicrophone}
            />
          </Paper>
        </Grid>
        <Grid item xs={4} sm={4}>        

          <Divider />
          <Paper elevation={3}>
            <div style={{ padding: 5 }}>
              <h4>Partes envolvidas na solicitação</h4>
              <Divider />
              <PartesEnvolvidasAtendimento
              usuarios={dataRoom.partes_envolvidas}
            />
            </div>
          </Paper>


        </Grid>

        <Grid item xs={4} sm={4}>
          <Paper elevation={3}>
            <div style={{ padding: 5 }}>
              <h4>Descrição triagem</h4>
              <div style={{ height: 200, overflowY: "scroll" }}>
                <h5>Triagem: {formatName(dataRoom.nomeAtendenteTriagem)}</h5>
                {dataRoom?.triagem_descricao}
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6}>
          {criarDescricao ? (
            <>
              <h4>Digite os detalhes da chamada </h4>
              <textarea
                value={descricao}
                style={{
                  height: "60%",
                  borderRadius: 10,
                  border: "1px solid #8e8e8e",
                  width: "100%",
                  resize: "none",
                  overflowY: "auto",
                  whiteSpace: "pre-wrap",
                }}
                onChange={(e) => setDescricao(e.target.value)}
              />
              <div>
                <ButtonComponent
                  onClick={() => EntarChamadaTriagem()}
                  fontWeight="500"
                  fullWidth={true}
                >
                  Salvar comentário
                </ButtonComponent>
              </div>
            </>
          ) : (
            <div>
              {dataRoom?.atendente_descricao?.length > 0 ? (
                <Paper elevation={3}>
                  <div style={{ padding: 5 }}>
                    <h4>Descrição atendimento adicionada</h4>
                    <div style={{ height: 150, overflowY: "scroll" }}>
                      <h5>Atendente: {formatName(dataRoom.nomeAtendente)}</h5>

                      {dataRoom?.atendente_descricao}
                    </div>
                  </div>
                  <ButtonComponent
                    onClick={() => setCriarDescricao(true)}
                    fontWeight="500"
                    fullWidth={true}
                  >
                    Editar descrição
                  </ButtonComponent>
                </Paper>
              ) : (
                <div>
                  <h4>Clique abaixo para adicionar uma descrição</h4>
                  <ButtonComponent
                    onClick={() => setCriarDescricao(true)}
                    fontWeight="500"
                    fullWidth={true}
                  >
                    Adicionar descrição
                  </ButtonComponent>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ChamadaAtendimentoRoute;
