import React from "react";
import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TableFooter, Typography } from "@mui/material";

type RowStyleFunction = (rowData: any) => object;

interface IColumns {
  title: string;
  field?: string;
  headerStyle?: object;
  cellStyle?: object | RowStyleFunction;
  style?: object;
  render?(value: any, index: number):any
  actions?(value: any, index: number):JSX.Element
}

interface IOptions {
  defaultMessage?: string;
  headerStyle?: object;
  cellStyle?: object;
  rowStyle?: object | RowStyleFunction;
}

interface IDataTable {
    columns: IColumns[];
    data: any[];
    paginateComponent?: JSX.Element;
    sx?: object,
    enableHover?: boolean;
    onRowClick?(event: React.SyntheticEvent, value: any): any;
    options?: IOptions;
}

export const DataTable = (props: IDataTable) => {
  const { columns, data, paginateComponent, sx, enableHover=true, onRowClick = null, options } = props;

  const sxCell: object = {
    borderBottom: `1px solid #DFE3E8`,
    minHeight: 50,
    height: '30px',
    lineHeight: 1,
    textAlign: 'center',
    position: 'static',
    padding: 0,
    borderLeft: 0,
    borderRight: 0,
    paddingRight: "5px",
    paddingLeft: "5px",
  }

  const sxRow: object = {
    height: '35px',
    '&$selected': {
      backgroundColor: '#f8fafc'
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: '#f8fafc'
      }
    },
    padding: 0,
    lineHeight: 0,
    cursor: onRowClick ? 'pointer' : 'auto',
  }

  return (
    <TableContainer sx={sx} component={Paper}>
      <Table sx={{width: "100%"}} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            { columns.map( (item, index) => (
              <TableCell
                size="small"
                key={`C${index}`}
                sx={{
                  ...sxCell,
                  ...options?.headerStyle,
                  ...item?.style,
                  ...item?.headerStyle,
                }}
              >
                {item.title}
              </TableCell>
            ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { data?.length > 0 ? 
              data.map( (value, rowIndex) => (
                <TableRow
                  hover={enableHover}
                  key={`V${rowIndex}`}
                  sx={{
                    ...sxRow,
                    ...options?.rowStyle,
                    ...(() => typeof options?.rowStyle === 'object'
                          ? options?.rowStyle
                          : typeof options?.rowStyle === 'function'
                            ? options?.rowStyle(value)
                            : {})(),
                  }}
                  onClick={(event) => onRowClick && onRowClick(event, value)}
                >
                  { columns.map( (item, colIndex) => (
                    <TableCell
                      size="small"
                      key={`VC${colIndex}`}
                      sx={{
                        ...sxCell,
                        ...options?.cellStyle,
                        ...item?.style,
                        ...(() => typeof item?.cellStyle === 'object'
                          ? item?.cellStyle
                          : typeof item?.cellStyle === 'function'
                            ? item?.cellStyle(value)
                            : {})(),
                      }}
                    >
                      {item.actions ?
                          item.actions(value, rowIndex)
                        : item.render ? 
                          item.field ? 
                            item.render(value[item.field], rowIndex)
                            :
                            item.render(value, rowIndex)
                        :
                          item.field && value[item.field]
                      }
                    </TableCell>
                  ))}
                </TableRow>))
              : (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography sx={{ textAlign: 'center' }}>
                      {options?.defaultMessage ? options.defaultMessage : 'Não há registros para serem exibidos.'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
          { paginateComponent && (
            <TableFooter>
              <TableRow>
                  { paginateComponent }
              </TableRow>
            </TableFooter>
          )}
      </Table>
    </TableContainer>
  );
};
