


import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/modalidades";

export function loadModalidades(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.get(`/api/v1/modalidades/listar?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.MODALIDADES_DATA, payload: response.data.modalidades },
          { type: types.MODALIDADES_DATA_PAGE, payload: response.data.page },
          { type: types.MODALIDADES_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.MODALIDADES_DATA_TOTAL, payload: response.data.total },
          { type: types.MODALIDADES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function loadModalidadesPorId(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.get(`/api/v1/modalidades/listar/modalidade?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.MODALIDADE_SELECIONADA_USUARIOS, payload: response.data.modalidade_principal },
          { type: types.MODALIDADE_SELECIONADA_DATA_PAGE, payload: response.data.page },
          { type: types.MODALIDADE_SELECIONADA_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.MODALIDADE_SELECIONADA_DATA_TOTAL, payload: response.data.total },
          { type: types.MODALIDADES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function loadCategoriaSecundaria(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.get(`/api/v1/modalidades/listar/categoria-secundaria?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.CATEGORIAS_SECUNDARIAS_DATA, payload: response.data.categoria_secundaria },
          { type: types.CATEGORIA_SECUNDARIA_PAGE, payload: response.data.page },
          { type: types.CATEGORIA_SECUNDARIA_PER_PAGE, payload: response.data.per_page },
          { type: types.CATEGORIA_SECUNDARIA_TOTAL, payload: response.data.total },
          { type: types.MODALIDADES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionCadatrarModalidades(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.post(`/api/v1/modalidades/cadastro-modalidades`, data).then(
      (response) => {
        store.dispatch([{ type: types.MODALIDADES_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionCadatrarModalidadesCategoria(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.post(`/api/v1/modalidades/cadastro-modalidades/categoria`, data).then(
      (response) => {
        store.dispatch([{ type: types.MODALIDADES_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionCadatrarModalidadesCategoriaSecundaria(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.post(`/api/v1/modalidades/cadastro-modalidades/categoria-secundaria`, data).then(
      (response) => {
        store.dispatch([{ type: types.MODALIDADES_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionAtualizarModalidades(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.put(`/api/v1/modalidades/atualizar`, data).then(
      (response) => {
        store.dispatch([{ type: types.MODALIDADES_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionExcluirEmpresaEClientes(id) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.delete(`/api/v1/modalidades/excluir/${id}`).then(
      (response) => {
        store.dispatch([         
          { type: types.MODALIDADES_LOADING, payload: false },
        ]);

        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionCadatrarClienteExcel(id, data) {
  const formData = new FormData();
  formData.append('excelFile', data); 

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.post(`/api/v1/cliente/excel/${id}`,  formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }}).then(
      (response) => {
        store.dispatch([
          { type: types.MODALIDADES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function CadastrarHorariosDeAtendimentoPorModalidade(id, data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.post(`/api/v1/modalidades/horarios/${id}`, data).then(
      (response) => {
        store.dispatch([{ type: types.MODALIDADES_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function LoadHorariosDeAtendimentoPorModalide(id) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.MODALIDADES_LOADING, payload: true });
    http.get(`/api/v1/modalidades/horarios/listar/${id}`).then(
      (response) => {
        store.dispatch([
          { type: types.HORARIOS_MODALIDADE_DATA, payload: response.data },
          { type: types.MODALIDADES_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.MODALIDADES_LOADING, payload: false });
        reject(e);
      }
    );
  });
}