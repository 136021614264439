export const USUARIO_LOADING = 'USUARIO_LOADING';
export const USUARIO_SET = 'USUARIO_SET';
export const USUARIO_SET_PERMISSOES = 'USUARIO_SET_PERMISSOES';
export const CHANGE_USUARIO_LOADING = 'CHANGE_USUARIO_LOADING';
export const CHANGE_CLIENTE_DATA = 'CHANGE_CLIENTE_DATA';

export const CHANGE_CLIENTE_DADOS_LOAD = 'CHANGE_CLIENTE_DADOS_LOAD';
export const CHANGE_CLIENTE_PLANOS = 'CHANGE_CLIENTE_PLANOS';

export const CHANGE_PLANO_CLIENTE_LOADING = 'CHANGE_PLANO_CLIENTE_LOADING';



export const CHANGE_DADOS_ASSINATURA_LOADING = 'CHANGE_DADOS_ASSINATURA_LOADING';
export const CHANGE_DADOS_ASSINATURA_LOAD = 'CHANGE_DADOS_ASSINATURA_LOAD';

export const SET_ATENDIMENTOS_FINALIZADOS_USUARIO = 'SET_ATENDIMENTOS_FINALIZADOS_USUARIO';
export const ATENDIMENTO_FINALIZADO_LOADING = 'ATENDIMENTO_FINALIZADO_LOADING';


export const ATENDIMENTO_FINALIZADO_PAGE = 'ATENDIMENTO_FINALIZADO_PAGE';
export const ATENDIMENTO_FINALIZADO_PER_PAGE = 'ATENDIMENTO_FINALIZADO_PER_PAGE';
export const ATENDIMENTO_FINALIZADO_TOTAL = 'ATENDIMENTO_FINALIZADO_TOTAL';