import * as types from "../types/empresas";

const INITIAL_STATE = {
  loading: false,
  empresas: [],
  empresa: {},
  empresaClientes: [],
  empresaSelecionada: "",
  page: 1,
  per_page: 10,
  total: 0,
  pageCliente: 1,
  per_pageCliente: 8,
  totalCliente: 0,
  pageValidation: 1,
  usuarios_empresas: [],
  usuario_page: 1,
  usuario_per_page: 10,
  usuario_total: 0,
  empresa_selecionada_usuario: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.EMPRESAS_LOADING:
      return { ...state, loading: action.payload };

    case types.EMPRESAS_DATA:
      return { ...state, empresas: action.payload };

    case types.EMPRESA_DATA:
      return { ...state, empresa: action.payload };

    case types.EMPRESAS_CLIENTES_DATA:
      return { ...state, empresaClientes: action.payload };

    case types.EMPRESAS_SELECIONADA_DATA:
      return { ...state, empresaSelecionada: action.payload };

    case types.EMPRESAS_DATA_PAGE:
      return { ...state, page: action.payload };

    case types.EMPRESAS_DATA_PER_PAGE:
      return { ...state, per_page: action.payload };

    case types.EMPRESAS_DATA_TOTAL:
      return { ...state, total: action.payload };

      // USUARIO REDUCER    

    case types.EMPRESA_SELECIONADA_USUARIOS:
      return { ...state, empresa_selecionada_usuario: action.payload };

    case types.USUARIOS_EMPRESAS_DATA:
      return { ...state, usuarios_empresas: action.payload };

    case types.USUARIOS_EMPRESAS_DATA_PAGE:
      return { ...state, usuario_page: action.payload };

    case types.USUARIOS_EMPRESAS_DATA_PER_PAGE:
      return { ...state, usuario_per_page: action.payload };

    case types.USUARIOS_EMPRESAS_DATA_TOTAL:
      return { ...state, usuario_total: action.payload };




    case types.EMPRESAS_CLIENTES_DATA_PAGE:
      return { ...state, pageCliente: action.payload };

    case types.EMPRESAS_CLIENTES_DATA_PER_PAGE:
      return { ...state, per_pageCliente: action.payload };

    case types.EMPRESAS_CLIENTES_DATA_TOTAL:
      return { ...state, totalCliente: action.payload };

    case types.EMPRESAS_CLIENTES_DATA_VALIDATION:
      return { ...state, pageValidation: action.payload };

    default:
      return state;
  }
};
