import { createContext, useState, useContext, useMemo } from 'react';

import { IMenuItem } from '../types';
import { useSelector } from 'react-redux';
import MenuListagem from '../Router/menu';

type DrawerContextType = {
  isOpened: boolean;
  toggleIsOpened: (value: boolean) => void;
  menu: IMenuItem[];
};

type DrawerContextProviderProps = {
  children: React.ReactNode;
};



const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export const DrawerContextProvider = ({ children }: DrawerContextProviderProps) => {
  const [isOpened, toggleIsOpened] = useState(false);

  const { usuario } = useSelector((state:any) => state.usuario)



function removeDependentes(array) {
  return usuario.plano_id === 1 || usuario?.tipo_conta == 'D' ? array.filter(item => item.literal !== 'Dependentes') : array
}

const DATA_MENU = MenuListagem()

  const value = useMemo(() => ({
    isOpened,
    toggleIsOpened,
    menu: DATA_MENU
  })
  , [isOpened]);

  return (
    <DrawerContext.Provider value={value}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error(
      "useDrawerContext must be used within a DrawerContextProvider"
    );
  }
  return context;
};
