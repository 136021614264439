export const CHANGE_CLICKLIFEE_ESPECIALIDADES = 'CHANGE_CLICKLIFEE_ESPECIALIDADES';
export const CHANGE_CLICKLIFEE_ESPECIALIDADE = 'CHANGE_CLICKLIFEE_ESPECIALIDADE';
export const CHANGE_CLICKLIFEE_LOADING = 'CHANGE_CLICKLIFEE_LOADING';
export const CHANGE_CLICKLIFEE_QUESTIONARIO = 'CHANGE_CLICKLIFEE_QUESTIONARIO';
export const CHANGE_CLICKLIFEE_ANEXOS = 'CHANGE_CLICKLIFEE_ANEXOS';
export const CHANGE_CLICKLIFEE_PACIENTE = 'CHANGE_CLICKLIFEE_PACIENTE';
export const CHANGE_CLICKLIFEE_CADASTRO = 'CHANGE_CLICKLIFEE_CADASTRO';
export const CHANGE_CLICKLIFEE_QUESTIONARIO_FORMATADO = 'CHANGE_CLICKLIFEE_QUESTIONARIO_FORMATADO';
export const CHANGE_CLICKLIFEE_PACIENTE_TOKEN = 'CHANGE_CLICKLIFEE_PACIENTE_TOKEN';
export const CHANGE_CLICKLIFEE_ATENDIMENTO_ID = 'CHANGE_CLICKLIFEE_ATENDIMENTO_ID';
export const CHANGE_CLICKLIFEE_ATENDIMENTO_ABERTO = 'CHANGE_CLICKLIFEE_ATENDIMENTO_ABERTO';