import { TextField, TextFieldProps } from "@mui/material";



export function InputText(props) {
  const { value, ...restProps } = props;

  return (
    <TextField
      {...props}
      label={props.label}
      required={props.required}
      value={props.value || ""}
      onChange={props.onChange}
      inputProps={{ maxLength: props.maxLength, style: { height: '6px' } }}
      InputLabelProps={{ shrink: true }}
      margin="dense"
      variant="outlined"
      fullWidth
      style={{ height: '40px' }}
    />
  );
}
