import { Link } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { IMenuItem } from "../types";

type Props = IMenuItem & {
  selected?: boolean;
  onClick?: () => void;
};

export const MenuItem: React.FC<Props> = ({
  route,
  literal,
  Icon,
  selected,
  onClick,
}) => {
  const link = (
    <ListItem
      button
      selected={selected}
      sx={{
        "&.Mui-selected": {
          color: "#5F73A9",
          background: selected ? "#5F73A9" : "#fff",
          borderRadius: 1
        },
      }}
      onClick={onClick}
    >
      <ListItemIcon sx={[{ minWidth: "auto" }]}>
        <Icon sx={{ color: selected ? "#FFFFFF" : "#fff", width: 18 }} />
      </ListItemIcon>
      <div
        style={{      
          color: selected ? "#FFFFFF" : "#fff",
          fontWeight: selected ? "600" : "400",
          margin: "5px 0px 5px 10px",
          fontSize: 13,
          fontFamily: "Poppins",
        }}
      >
        {literal}
      </div>
    </ListItem>
  );

  return route ? <Link to={route}>{link}</Link> : link;
};
