export default function calcularTempoEmEspera(data_inicio) {
  const inicio = new Date(data_inicio);
  const agora = new Date();
  const diferenca = agora - inicio;

  const minutos = Math.floor(diferenca / (1000 * 60));
  const horas = Math.floor(minutos / 60);
  const minutosRestantes = minutos % 60;

  if (horas > 0) {
    return `${horas} hora(s) e ${minutosRestantes} minuto(s)`;
  } else {
    return `${minutos} minuto(s)`;
  }
}
