import { Divider, Grid, Paper, TextField } from "@mui/material";
import { InputText, Modal } from "../../../../components";
import ButtonComponent from "../../../../components/ButtonComponent";
import { useEffect, useState } from "react";
import ModalComponent from "../../../../components/MDModal";
import formatName from "../../../../helpers/formatName";
import Loading from "../../../../components/Loading";
import { ActionCadatrarModalidades, ActionCadatrarModalidadesCategoria } from "../../../../store/actions/modalidades";

function ModalAdd({ open, close, updateList, modalidade_id }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({  categoria_principal: "",  });

  const [mesageErro, setMessageErro] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setMessageErro("");
    }, 3000);
  }, [mesageErro]);

  async function AddCompany() {
    setLoading(true);
    const _data = {
      categoria_principal: data.categoria_principal, 
      modalidade_id: modalidade_id
    };
    try {
      await ActionCadatrarModalidadesCategoria(_data);
      updateList();
      setData({ modalidade: ""});
      setLoading(false);
      close();
    } catch (e) {
      console.log(e.response.data.titulo);
      setMessageErro(e?.response?.data?.titulo);
      setLoading(false);
    }
  }

  return (
    <ModalComponent open={open} width="30%">
      <Loading loading={loading} />
      <div style={{ padding: 15 }}>
        <h4>Insira o nome da modalidade para ser criada</h4>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <InputText
              type="text"
              value={data.categoria_principal ? data.categoria_principal : ""}
              onChange={(e) =>
                setData({
                  ...data,
                  categoria_principal: e.target.value
                    ? formatName(e.target.value)
                    : e.target.value,
                })
              }
              label="Categoria Atendimento"
            />
          </Grid>
    
        </Grid>
        <br />
        <ButtonComponent onClick={() => AddCompany(200)} fullWidth>
          Inserir modalidade
        </ButtonComponent>
        <br />
        <ButtonComponent
          onClick={close}
          style={{ backgroundColor: "#3a3a3a" }}
          fullWidth
        >
          Fechar
        </ButtonComponent>

        <h4 style={{ color: "red", textAlign: "center" }}>{mesageErro}</h4>
      </div>
    </ModalComponent>
  );
}

export default ModalAdd;
