import styled from "@emotion/styled";
import { List, ListItem, ListItemIcon } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { BiExit } from "react-icons/bi";
import { useSelector } from "react-redux";
import formatName from "../helpers/formatName";
import { MenuItem } from "./MenuItem";
import { versaoDash } from "../services/url";

const Container = styled.div`
  width: auto;
  height: 80vh;
`;

const UserName = styled.div`
  text-align: center;
  font-size: 18px;
  width: auto;
  color: #fff;
  margin-bottom: 15px;
`;

const Close = styled.div`
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: "#fff";
  padding: 5px 30px;
  border-radius: 15px;
`;

export const MenuItemsList = ({ items, isOpened }) => {
  const { pathname } = useLocation();
  const usuario = localStorage.getItem("usuario_nome");

  const navigate = useNavigate();

  if (!items.length) return null;

  function handleSignOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("usuario_nome");
    localStorage.removeItem("permissao");
    localStorage.removeItem("atendimento");
    localStorage.removeItem("id_usuario");
    navigate("/login", { replace: true });
  }

  return (
    <Container>
      <List sx={{ height: "auto", width: "auto" }}>
        <UserName>{usuario && formatName(usuario)}</UserName>

        {items.map(({ literal, route, Icon }) => (
          <MenuItem
            Icon={Icon}
            literal={literal}
            route={route}
            key={route}
            selected={pathname === route}
          />
        ))}
      </List>

      <ListItem button onClick={handleSignOut}>
        <ListItemIcon sx={[{ minWidth: "auto" }]}>
          <BiExit style={{ color: "#fff", width: 16 }} />
        </ListItemIcon>
        <div
          style={{
            color: "#ffffff",
            fontWeight: "400",
            margin: "5px 0px 5px 10px",
            fontSize: 14,
            fontFamily: "Poppins",
          }}
        >
          Sair da Plataforma

        </div>
      </ListItem>
        
      <div style={{alignContent: "center", alignItems: 'center', justifyContent: 'center', color: '#fff', textAlign: "center", fontSize: 10}}>
         V {versaoDash}
      </div>
    </Container>
  );
};
