import { Avatar, CircularProgress, Divider, Grid } from "@mui/material";
import { Modal } from "../../components";
import formatName from "../../helpers/formatName";
import { ButtonSelect } from "./style";
import ButtonComponent from "../../components/ButtonComponent";

function ModalEncaminhamentoChamada({
  setModalAtendente,
  modalAtendente,
  atendentesOnline,
  atendenteSelected,
  SelecionarAtendente,
  atendenteSelectedData,
  TransferirChamada,
  EscolherOutro,
  loading,
  colorBtn
}) {


  
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }




  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <Modal open={modalAtendente} width="100%" style={{ borderRadius: 14 }}>
      {!atendenteSelected ? (
        <>
          <h4 style={{ margin: 0 }}>
            Selecione um atendente para encaminhar a chamada
          </h4>
          <Divider />

          <div style={{ padding: "30px 0px" }}>
            <Grid container>
              {atendentesOnline.map((at, index) => {
                return (
                  <Grid item xs={3} sm={3} index={index.id}>
                    <ButtonSelect onClick={() => SelecionarAtendente(at)}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Avatar {...stringAvatar(at.nome)} />
                        &nbsp;{formatName(at.nome)}
                      </div>
                    </ButtonSelect>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </>
      ) : (
        <>
          <h4 style={{ margin: 0 }}>Você selecionou:</h4>
          <Divider />
          <div style={{ width: "50%", margin: "auto" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginBottom: 50,
                  border: "1px solid #bcbcbc",
                  padding: 15,
                  borderRadius: 12,
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Avatar {...stringAvatar(atendenteSelectedData.nome)} />
                  &nbsp;{formatName(atendenteSelectedData.nome)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                {!loading ? 
                <ButtonComponent onClick={() => TransferirChamada()}>
                  Confirmar seleção
                </ButtonComponent>
                :
                <>
                   <div style={{...colorBtn, backgroundColor: "#135f66", width: '50%'}}><CircularProgress style={{color: "#fff", width: 15, height: 15}}/>&nbsp;  Aguarde...</div>  
                </>
}
                <button
                  onClick={() => EscolherOutro()}
                  style={{
                    background: "#7c2626",
                    border: "none",
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontWeight: 500,

                    padding: "15px 25px",
                    cursor: "pointer",
                    borderRadius: 9,
                  }}
                >
                  Escolher outro
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <Divider />
      <button onClick={() => setModalAtendente(false)}>Fechar</button>
    </Modal>
  );
}

export default ModalEncaminhamentoChamada;
