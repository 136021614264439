import AudioTrack from './AudioTrack'
import VideoTrack from './VideoTrack'
import {useTrack} from '../../hooks/useTwilioVideo/index'

function Participant ({ participant }) {
  const { videoOn, audioOn, videoTrack, audioTrack } = useTrack({ participant })

  return (
    <div>
      {videoOn ? <VideoTrack track={videoTrack}  /> : <>camoff</>}
      <br />
      {audioOn ? <AudioTrack track={audioTrack} /> : ''}
    </div>
  )
}

export default Participant