import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon
} from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  Toolbar,
  useTheme
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import logohorizontal from "../assets/logohorizontal.png";
import { useDrawerContext } from "../contexts/drawer-context";

export const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { isOpened, toggleIsOpened } = useDrawerContext();




  const theme = useTheme();
  return (
    <>
      <AppBar sx={{ background: "#ffffff", color: "#6c59bf" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => toggleIsOpened(!isOpened)}
            sx={{ padding: theme.spacing(1) }}
          >
            {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <img src={logohorizontal} alt="logo" style={{ margin: "auto" }} />
        </Toolbar>
     
        
     
      </AppBar>
    </>
  );
};

export default Header;
