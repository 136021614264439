import { Divider, Grid, Paper, TextField } from "@mui/material";
import { InputText, Modal } from "../../../components";
import ButtonComponent from "../../../components/ButtonComponent";
import { useEffect, useState } from "react";
import ModalComponent from "../../../components/MDModal";
import formatName from "../../../helpers/formatName";
import MaskCPFCNPJ from "../../../helpers/maskCpfCnpj";
import { formatCelular } from "../../../helpers";
import { ActionCadatrarEmpresa } from "../../../store/actions/empresas";
import { removeDots } from "../../../helpers/removeDots";
import Loading from "../../../components/Loading";
import { ActionCadatrarParceiroIntegracao } from "../../../store/actions/parceiros_integracao";

function ModalAddEmpresa({ open, close, updateList }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    nome: "",
    cnpj: "",
    email: "",
    celular: "",
  });

  const [mesageErro, setMessageErro] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setMessageErro("");
    }, 3000);
  }, [mesageErro]);

  async function AddCompany() {
    setLoading(true);
    const _data = {
      nome: data.nome,
      cnpj: removeDots(data.cnpj),
      email: data.email,
      celular: removeDots(data.celular),
    };
    try {
      await ActionCadatrarParceiroIntegracao(_data);
      updateList();
      setData({ nome: "", cnpj: "", email: "", celular: "" });
      setLoading(false);
      close();
    } catch (e) {
      console.log(e.response.data.titulo);
      setMessageErro(e?.response?.data?.titulo);
      setLoading(false);
    }
  }

  return (
    <ModalComponent open={open} width="50%">
      <Loading loading={loading} />
      <div style={{ padding: 15 }}>
        <h4>Insira os dados para incluir a empresa que fará atendimento</h4>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={7} sm={7}>
            <InputText
              type="text"
              value={data.nome ? data.nome : ""}
              onChange={(e) =>
                setData({
                  ...data,
                  nome: e.target.value
                    ? formatName(e.target.value)
                    : e.target.value,
                })
              }
              label="Razão Social"
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <InputText
              type="text"
              value={data.cnpj}
              onChange={(e) =>
                setData({ ...data, cnpj: MaskCPFCNPJ(e.target.value) })
              }
              label="CNPJ"
            />
          </Grid>
          <Grid item xs={7} sm={7}>
            <InputText
              type="email"
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
              label="E-mail"
            />
          </Grid>
          <Grid item xs={5} sm={5}>
            <InputText
              type="text"
              value={data.celular}
              onChange={(e) =>
                setData({ ...data, celular: formatCelular(e.target.value) })
              }
              label="Celular"
            />
          </Grid>
        </Grid>
        <br />
        <ButtonComponent onClick={() => AddCompany(200)} fullWidth>
          Inserir empresa
        </ButtonComponent>
        <br />
        <ButtonComponent
          onClick={close}
          style={{ backgroundColor: "#3a3a3a" }}
          fullWidth
        >
          Fechar
        </ButtonComponent>

        <h4 style={{ color: "red", textAlign: "center" }}>{mesageErro}</h4>
      </div>
    </ModalComponent>
  );
}

export default ModalAddEmpresa;
