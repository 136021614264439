


import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/parceiros_integracao";

export function loadIntegradores(query) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: true });
    http.get(`/api/v1/integradores/listar?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.PARCEIROS_INTEGRACAO_DATA, payload: response.data.integradores },
          { type: types.PARCEIROS_INTEGRACAO_DATA_PAGE, payload: response.data.page },
          { type: types.PARCEIROS_INTEGRACAO_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.PARCEIROS_INTEGRACAO_DATA_TOTAL, payload: response.data.total },
          { type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function loadIntegradoresUsuarios(query, parceiro_id) {
  const qrt = customQueryStringify(query);

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: true });
    http.get(`/api/v1/integradores/listar/${parceiro_id}?${qrt}`).then(
      (response) => {
        store.dispatch([
          { type: types.USUARIOS_PARCEIROS_DATA, payload: response.data.usuarios },
          { type: types.PARCEIRO_SELECIONADA_USUARIOS, payload: response.data.parceiro },
          { type: types.USUARIOS_PARCEIROS_DATA_PAGE, payload: response.data.page },
          { type: types.USUARIOS_PARCEIROS_DATA_PER_PAGE, payload: response.data.per_page },
          { type: types.USUARIOS_PARCEIROS_DATA_TOTAL, payload: response.data.total },
          { type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionCadatrarParceiroIntegracao(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: true });
    http.post(`/api/v1/integradores/cadastro-integradores`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionCadatrarParceiroUsuario(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: true });
    http.post(`/api/v1/integradores/cadastro-integradores/usuario`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false });
        reject(e);
      }
    );
  });
}

export function ActionAtualizarModalidades(data) {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: true });
    http.put(`/api/v1/integradores/atualizar`, data).then(
      (response) => {
        store.dispatch([{ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false }]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionExcluirEmpresaEClientes(id) {

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: true });
    http.delete(`/api/v1/integradores/excluir/${id}`).then(
      (response) => {
        store.dispatch([         
          { type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false },
        ]);

        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false });
        reject(e);
      }
    );
  });
}


export function ActionCadatrarClienteExcel(id, data) {
  const formData = new FormData();
  formData.append('excelFile', data); 

  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: true });
    http.post(`/api/v1/cliente/excel/${id}`,  formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }}).then(
      (response) => {
        store.dispatch([
          { type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.PARCEIROS_INTEGRACAO_LOADING, payload: false });
        reject(e);
      }
    );
  });
}