import * as types from "../types/usuario";

const INITIAL_STATE = {
  loading: false,
  usuario: {},
  plano: {},
  permissoes: {},
  consumo: {},
  planoClienteLoading: false,
  clienteLoad: {},
  assinaturaLoading: false,
  assinaturaLoad: {},
  atendimentosFinalizados: {},
  atendimentosFinalizadosLoading: false,
  atendimentoPage: 1,
  atendimentoPerPage: 10,
  atendimentoTotal: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.USUARIO_LOADING:
      return { ...state, loading: action.payload };
    case types.USUARIO_SET:
      return { ...state, ...action.payload };


    case types.USUARIO_SET_PERMISSOES:
      return { ...state, permissoes: action.payload };

    case types.CHANGE_CLIENTE_PLANOS:
      return { ...state, plano: action.payload };

    case types.CHANGE_PLANO_CLIENTE_LOADING:
      return { ...state, planoClienteLoading: action.payload };

    case types.CHANGE_CLIENTE_DADOS_LOAD:
      return { ...state, clienteLoad: action.payload };

    case types.CHANGE_DADOS_ASSINATURA_LOADING:
      return { ...state, assinaturaLoading: action.payload };

    case types.CHANGE_DADOS_ASSINATURA_LOAD:
      return { ...state, assinaturaLoad: action.payload };

    case types.SET_ATENDIMENTOS_FINALIZADOS_USUARIO:
      return { ...state, atendimentosFinalizados: action.payload };

    case types.ATENDIMENTO_FINALIZADO_LOADING:
      return { ...state, atendimentosFinalizadosLoading: action.payload };





    case types.ATENDIMENTO_FINALIZADO_PAGE:
      return { ...state, atendimentoPage: action.payload };

    case types.ATENDIMENTO_FINALIZADO_PER_PAGE:
      return { ...state, atendimentoPerPage: action.payload };

    case types.ATENDIMENTO_FINALIZADO_TOTAL:
      return { ...state, atendimentoTotal: action.payload };




    default:
      return state;
  }
};
