const ButtonComponent = ({
  text,
  onClick,
  disabled,
  children,
  fontWeight,
  type,
  fullWidth,
  width,
  style
}) => {
  const defaultStyle = {
    background: disabled ? "#F5F5F5" : "#86BFC6",
    border: "none",
    color: disabled ? "#616161" : "#fff",
    fontFamily: "Poppins",
    fontWeight: fontWeight ? fontWeight : 500,
    flexDirection: "row",
    display: "flex",
    padding: "15px 25px",
    cursor: "pointer",
    borderRadius: 9,
    width: fullWidth ? "100%" : width,
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={{
        ...defaultStyle,
        ...style, // Apply the custom style last to override any default styles
      }}
    >
      {children}
    </button>
  );
};

export default ButtonComponent;
