import { Modal as ReactModal, Backdrop, Fade } from "@mui/material";
import styled from "@emotion/styled";

export const Modal = ({ open = false, width, height, bg, children, style }) => {
  const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const PaperContainer = styled.div`
    display: inline-block;
    position: absolute;
    background: #ffffff;
    max-width: 800px;
    padding: 2em;
  `;

  return (
    <>
      {open && (
        <ModalContainer>
          <ReactModal
            open={open}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
        
          >
            <Fade in={open}>
              <PaperContainer
                style={{
                  ...style,
                  width: width ? width : "inherit",
                  height: height ? height : "inherit",
                  background: bg,
                }}
              >
                {children}
              </PaperContainer>
            </Fade>
          </ReactModal>
        </ModalContainer>
      )}
    </>
  );
};

export default Modal;
