import { useNavigate } from "react-router-dom";
import customQueryStringify from "../../helpers/queryString";
import http from "../../services/api";
import store from "../index";
import * as types from "./../types/usuario";
import videochamadapng from '../../assets/desconectado.png'

export const autenticarUsuario = async (email, password) => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIO_LOADING, payload: true });
    http.post(`/api/v1/usuarios/autenticar`, { email, password }).then(
      ({ data }) => {
        store.dispatch({ type: types.USUARIO_SET, payload: data });
        localStorage.setItem("token", data.token);
        localStorage.setItem("permissao", data.permissao.tipo);
        localStorage.setItem("usuario_nome", data.usuario.nome);
        localStorage.setItem("atendimento", "DISPONIVEL");
        localStorage.setItem("id_usuario", data.usuario.id);
        store.dispatch({ type: types.USUARIO_LOADING, payload: false });
        resolve(data);
      },
      (e) => {
        store.dispatch({ type: types.USUARIO_LOADING, payload: false });
        reject(e ? e.response.data : "Não foi possível efetuar o login");
      }
    );
  });
};

export function loadUsuario() {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: types.USUARIO_LOADING, payload: true });
    http.get(`/api/v1/usuarios/load`).then(
      (response) => {
        store.dispatch([
          { type: types.USUARIO_SET, payload: response.data },
          {
            type: types.USUARIO_SET_PERMISSOES,
            payload: response.data.permissao,
          },
          { type: types.USUARIO_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({ type: types.USUARIO_LOADING, payload: true });
        reject(e);
      }
    );
  });
}

export function AtendimentosFinalizadosUsuario(data) {
  const filter = customQueryStringify(data);

  return new Promise((resolve, reject) => {
    store.dispatch({
      type: types.ATENDIMENTO_FINALIZADO_LOADING,
      payload: true,
    });
    http.get(`/api/v1/atendimento/finalizados?${filter}`).then(
      (response) => {
        store.dispatch([
          {
            type: types.SET_ATENDIMENTOS_FINALIZADOS_USUARIO,
            payload: response.data,
          },
          {
            type: types.ATENDIMENTO_FINALIZADO_PAGE,
            payload: response.data.pagination.currentPage,
          },
          {
            type: types.ATENDIMENTO_FINALIZADO_PER_PAGE,
            payload: response.data.pagination.perPage,
          },
          {
            type: types.ATENDIMENTO_FINALIZADO_TOTAL,
            payload: response.data.pagination.total,
          },
          { type: types.ATENDIMENTO_FINALIZADO_LOADING, payload: false },
        ]);
        resolve(response.data);
      },
      (e) => {
        store.dispatch({
          type: types.ATENDIMENTO_FINALIZADO_LOADING,
          payload: false,
        });
        reject(e);
      }
    );
  });
}




export function ValidationLogin() {
  return new Promise((resolve, reject) => {
    http.get(`/api/v1/usuarios/validation-login`).then(
      (response) => {
        resolve(response.data);
      },
      (e) => {
        if (e.response && e.response.status === 401) {
          // Remover dados do localStorage
          localStorage.removeItem("token");
          localStorage.removeItem("usuario_nome");
          localStorage.removeItem("atendimento");
          localStorage.removeItem("permissao");
          localStorage.removeItem("id_usuario");

          reject(e);
        }
      }
    );
  });
}