import * as types from "../types/parceiros_integracao";

const INITIAL_STATE = {
  loading: false,
  parceiros_integracao: [],
  parceiro_integracao: {},
  page: 1,
  per_page: 10,
  total: 0,


  usuarios_parceiros: [],
  usuario_page: 1,
  usuario_per_page: 10,
  usuario_total: 0,
  parceiro_selecionada_usuario: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PARCEIROS_INTEGRACAO_LOADING:
      return { ...state, loading: action.payload };

    case types.PARCEIROS_INTEGRACAO_DATA:
      return { ...state, parceiros_integracao: action.payload };

    case types.PARCEIRO_INTEGRACAO_DATA:
      return { ...state, parceiro_integracao: action.payload };


    case types.PARCEIROS_INTEGRACAO_DATA_PAGE:
      return { ...state, page: action.payload };

    case types.PARCEIROS_INTEGRACAO_DATA_PER_PAGE:
      return { ...state, per_page: action.payload };

    case types.PARCEIROS_INTEGRACAO_DATA_TOTAL:
      return { ...state, total: action.payload };


      // PARCEIROS USUARIOS

      case types.PARCEIRO_SELECIONADA_USUARIOS:
        return { ...state, parceiro_selecionada_usuario: action.payload };
  
      case types.USUARIOS_PARCEIROS_DATA:
        return { ...state, usuarios_parceiros: action.payload };
  
      case types.USUARIOS_PARCEIROS_DATA_PAGE:
        return { ...state, usuario_page: action.payload };
  
      case types.USUARIOS_PARCEIROS_DATA_PER_PAGE:
        return { ...state, usuario_per_page: action.payload };
  
      case types.USUARIOS_PARCEIROS_DATA_TOTAL:
        return { ...state, usuario_total: action.payload };


    default:
      return state;
  }
};
